import React from "react";
import {SelectionMode, ShimmeredDetailsList} from "@fluentui/react";
import {useDispatch} from "react-redux";

import type ConstructionsTableProps from "./ConstructionTableProps";
import useConstructionTableSelectionHook from "../../hooks/useConstructionTableSelectionHook";
import useConstructionTableColumns from "../../hooks/useConstructionTableColumns";
import ConstructionPagination from "../pagination/ConstructionPagination";
import IsaacEmpty from "../../../../components/isaacEmpty/IsaacEmpty";

import {changePage} from "../../constructionsSlice";

const ConstructionsTable: React.FunctionComponent<ConstructionsTableProps> = ({
  handleSelection,
  loading,
  page,
  pagination,
}) => {
  const dispatch = useDispatch();

  const handleConstructionSelection = (selectedConstructions: any[]): void => {
    handleSelection(selectedConstructions);
  };

  const selection = useConstructionTableSelectionHook(
    handleConstructionSelection,
  );
  const columns = useConstructionTableColumns();

  return (
    <>
      <ShimmeredDetailsList
        enableShimmer={loading}
        isSelectedOnFocus={true}
        enableUpdateAnimations={true}
        onShouldVirtualize={() => true}
        items={page}
        columns={columns}
        setKey="set"
        selection={selection}
        selectionPreservedOnEmptyClick={true}
        ariaLabelForSelectionColumn="Toggle selection"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        listProps={{
          renderedWindowsAhead: 0,
          renderedWindowsBehind: 0,
        }}
        selectionMode={SelectionMode.multiple}
      />

      {page?.length <= 0 && !loading && (
        <IsaacEmpty message="There are no constructions yet..." />
      )}

      {page?.length > 0 && (
        <ConstructionPagination
          handlePageChange={(pageNumber: number) => {
            dispatch(changePage(pageNumber));
          }}
          pagination={pagination}
        />
      )}
    </>
  );
};

export default ConstructionsTable;
