import React from "react";
import styles from "./ConstructionDetailsSection.module.scss";
import type {ConstructionGUIData} from "../../../../types/ConstructionGUIData";
import type IConstructionDetailsSectionProps from "./IConstructionDetailsSectionProps";
import {useAuth} from "../../../../services/auth/hooks/useAuth";
import {
  addToLocaLStorage,
  getFromLocalStorage,
} from "../../../../utils/ManageLocalStorage";
import IsaacCollapsible from "../../../../components/isaacCollapsible/IsaacCollapsible";
import Table from "../../../../components/_legacy/table/Table";
import ConstructionStatus from "../../../../types/ConstructionStatus";

const getHiddenElementsFromLocalStorage = (): string[] => {
  return getFromLocalStorage<string[]>("hiddenTables") ?? ["mastCoordinates"];
};
const ConstructionDetailsSection: React.FC<
  IConstructionDetailsSectionProps
> = ({
  section,
  subSections,
  constructionId,
  constructionStatus,
}: IConstructionDetailsSectionProps) => {
  const {user} = useAuth();
  const isAllowedToTakeActionByRole = user.isAllowedToTakeAction();
  const isAllowedToEditConstructionByRoleAndState =
    isAllowedToTakeActionByRole &&
    constructionStatus !== ConstructionStatus.Frozen;
  const hiddenTables = getHiddenElementsFromLocalStorage();

  const handleCollapse = (event: React.MouseEvent<HTMLElement>): void => {
    const hdd = getHiddenElementsFromLocalStorage();
    const index = hdd.findIndex(t => t === event.currentTarget.id);

    if (index !== -1) {
      hdd.splice(index, 1);
    } else {
      hdd.push(event.currentTarget.id);
    }
    addToLocaLStorage("hiddenTables", hdd);
  };

  return (
    <>
      {section !== undefined && (
        <div
          id={section.id + "_section"}
          className={styles.constructionDataSection}>
          {section?.count > 0 && (
            <IsaacCollapsible
              count={section.count}
              name={section.name}
              onCollapse={handleCollapse}
              isCollapsed={hiddenTables.some(
                (t: string) => t === section?.name,
              )}>
              {/* Note: Table needs to be reworked */}
              <Table
                name={section.name}
                columns={section.columns}
                values={section.items}
                count={section.count}
                tableId={section.id}
                constructionId={constructionId}
                isAllowToTakeAction={isAllowedToEditConstructionByRoleAndState}
              />
            </IsaacCollapsible>
          )}
          {subSections?.map(
            (subSection: ConstructionGUIData, index: number) =>
              subSection?.count > 0 && (
                // Note: Table needs to be reworked
                <IsaacCollapsible
                  key={index}
                  count={subSection.count}
                  name={subSection.name}
                  onCollapse={handleCollapse}
                  isCollapsed={hiddenTables.some(t => t === subSection?.name)}>
                  <Table
                    key={index}
                    name={subSection.name}
                    columns={subSection.columns}
                    values={subSection.items}
                    count={subSection.count}
                    tableId={subSection.id}
                    constructionId={constructionId}
                    parent={section.name}
                    isAllowToTakeAction={
                      isAllowedToEditConstructionByRoleAndState
                    }
                  />
                </IsaacCollapsible>
              ),
          )}
        </div>
      )}
    </>
  );
};

export default ConstructionDetailsSection;
