import React, {useState} from "react";
import {IconButton} from "@fluentui/react";
import styles from "./IsaacCollapsible.module.scss";

import type IsaacCollapsibleProps from "./IsaacCollapsibleProps";
import TranslatedText from "../_legacy/translatedText/TranslatedText";
import IsaacBadge from "../isaacBadge/IsaacBadge";

const IsaacCollapsible: React.FunctionComponent<IsaacCollapsibleProps> = ({
  children,
  count,
  name,
  isCollapsed,
  onCollapse,
}) => {
  const [isComponentCollapsed, setIsComponentCollapsed] =
    useState<boolean>(isCollapsed);

  const handleOnClick = (event: React.MouseEvent<HTMLElement>): void => {
    setIsComponentCollapsed(!isComponentCollapsed);
    if (onCollapse !== undefined) {
      onCollapse(event);
    }
  };

  return (
    <div className={styles.collapsibleContainer}>
      <div className={styles.collapsibleHeader}>
        <div className={styles.title}>
          <IconButton
            iconProps={{
              iconName: isComponentCollapsed ? "ChevronDown" : "ChevronUp",
            }}
            title={isComponentCollapsed ? "Show" : "Hide"}
            ariaLabel="Collapse"
            id={name}
            onClick={handleOnClick}
          />
          {name !== undefined && <TranslatedText value={name} />}
          <IsaacBadge
            title="Count"
            className={styles.total}
            isVisible={count !== undefined}>
            {count}
          </IsaacBadge>
        </div>
        {!isComponentCollapsed && children}
      </div>
    </div>
  );
};

export default IsaacCollapsible;
