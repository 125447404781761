import SrvCore from "../../../utils/_legacy/SrvCore";

export const getICCConstructions = (items: any): any[] => {
  const constructions = [];

  for (let i = 0; i < items.length; i++) {
    const construction = items[i];
    constructions.push({
      key: construction.constructionId,
      id: construction.constructionId,
      name: construction.name !== undefined ? construction.name : "",
      bjornId: construction.number !== undefined ? construction.number : "",
      phase: construction.phase,
      locationCode:
        construction.location !== undefined &&
        construction.location.code !== undefined
          ? construction.location.code
          : "",
      locationType:
        construction.location !== undefined &&
        construction.location.type !== undefined
          ? construction.location.type
          : "",
      isGeometricallyValid: construction.isGeometricallyValid,
      geometricalValidationErrorMessages:
        construction.geometricalValidationErrorMessages,
      createdBy:
        construction.createdBy !== undefined ? construction.createdBy.name : "",
      createdByUpn:
        construction.createdBy !== undefined ? construction.createdBy.upn : "",
      createdOn:
        construction.createdOn !== undefined
          ? SrvCore.formatDate(
              new Date(construction.createdOn as string),
              "DD/MM/YYYY HH:MM",
            )
          : "",
      isSelected: false,
      sortCreatedOn:
        construction.createdOn !== undefined
          ? new Date(construction.createdOn as string)
          : new Date(),
      tags: construction.tags,
      status: construction.status,
    });
  }

  return constructions;
};
