import type {SignalRMessage} from "../../../services/signalR/models/Messages";
import {toast} from "react-toastify";

const useDefaultHandler = (): ((message: SignalRMessage) => void) => {
  const execute = (message: SignalRMessage): void => {
    toast(`${message.type} executed successfully!`, {
      toastId: message.data.id,
    });
  };
  return execute;
};

export default useDefaultHandler;
