const enum PathConstants {
  Home = "/",
  Login = "login",
  Error = "/error",
  NotFound = "/notfound",
  Constructions = "/constructions",
  Calculations = "calculations",
}

export default PathConstants;
