import {useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";

import type {RootState} from "../../../store";
import type {SignalRMessage} from "../../../services/signalR/models/Messages";
import type IConstruction from "../../construction/types/IConstruction";
import type ICalculation from "../../../types/ICalculation";
import type ICalculationConstructionDetails from "../../../types/ICalculationConstructionDetails";
import type IProfileCalculation from "../../../types/IProfileCalculation";

import useConstructionHook from "../../../hooks/useConstructionHook";
import useCalculationHook from "../../../hooks/useCalculationHook";
import {getOrderByCreatedDate} from "../../../utils/DateFormatting";
import {useAuth} from "../../../services/auth/hooks/useAuth";
import {load as loadConstructions} from "../../constructions/constructionsSlice";
import {load as loadCurrentConstruction} from "../../construction/constructionSlice";
import {load as loadCalculation} from "../constructionCalculationsSlice";
import useSaveModifiedConstructionHook from "./useSaveModifiedConstructionHook";
import ConstructionStatus from "../../../types/ConstructionStatus";

const useConstructionCalculationsPageHook = (): {
  calculations: ICalculation[];
  selectedMastNo: string | undefined;
  selectedCalculationResults: any | undefined;
  constructionDetails: ICalculationConstructionDetails | undefined;
  isAllowToTakeAction: boolean;
  construction: IConstruction | null;
  messages: SignalRMessage[];
  loadPageData: (selectedCalculationId: any | null) => Promise<void>;
  handleSaveModifiedConstruction: () => Promise<void>;
} => {
  const {id} = useParams();
  const {user} = useAuth();
  const dispatch = useDispatch();

  const {all} = useSelector((state: RootState) => state.constructions);
  const {data: construction} = useSelector(
    (state: RootState) => state.construction,
  );

  const isConstructionActive =
    all.find(c => c.id === id)?.status !== ConstructionStatus.Frozen;
  const {messages} = useSelector((state: RootState) => state.signalRMessages);

  const {getConstructionById, getAllConstructions} = useConstructionHook();
  const saveModifiedConstruction = useSaveModifiedConstructionHook();

  const {
    getCalculationsByConstructionId,
    getCalculationProfileResults,
    getConstructionDetails,
  } = useCalculationHook();
  const {
    calculations,
    selectedMastNo,
    constructionDetails,
    selectedCalculationResults,
  } = useSelector((state: RootState) => state.constructionCalculations);

  const getConstructions = async (): Promise<any[]> => {
    if (all.length <= 0) {
      const response = await getAllConstructions();
      return getOrderByCreatedDate(response?.data ?? []);
    }
    return all;
  };

  const getCurrentConstruction = async (): Promise<IConstruction | null> => {
    if (id !== undefined) {
      const response = await getConstructionById(id);
      return response.data;
    }
    return null;
  };

  const getCalculations = async (): Promise<ICalculation[]> => {
    if (id !== undefined) {
      const calculationsResponse = await getCalculationsByConstructionId(id);

      return (
        calculationsResponse.data?.sort(
          (calculationA: ICalculation, calculationB: ICalculation) =>
            new Date(calculationB.createdOn).getTime() -
            new Date(calculationA.createdOn).getTime(),
        ) ?? []
      );
    }
    return [];
  };

  const getCalculationProfiles = async (
    selectedCalculation: ICalculation | undefined,
    selectedProfileCalculation: IProfileCalculation | undefined,
  ): Promise<any | undefined> => {
    if (
      selectedProfileCalculation !== undefined &&
      selectedCalculation !== undefined
    ) {
      const response = await getCalculationProfileResults(
        selectedCalculation.calculationId,
        selectedProfileCalculation.profileCalculationId,
      );
      return response.data;
    }
    return undefined;
  };

  const loadPageData = async (
    selectedCalculationId: string | null,
  ): Promise<void> => {
    const constructions = await getConstructions();
    const currentConstruction = await getCurrentConstruction();
    const lclCalculations = await getCalculations();

    let lclSelectedCalculation: ICalculation | undefined;
    let lclCurrentProfileCalculationResults: any | undefined;
    let lclConstructionDetails: ICalculationConstructionDetails | undefined;
    let lclCurrentMastNo: string | undefined;
    let lclSelectedProfileCalculation: IProfileCalculation | undefined;

    if (lclCalculations !== undefined && lclCalculations.length > 0) {
      lclSelectedCalculation =
        lclCalculations.find(c => c.calculationId === selectedCalculationId) ??
        lclCalculations[0];
      if (
        lclSelectedCalculation !== undefined &&
        lclSelectedCalculation !== null
      ) {
        lclSelectedCalculation.selected = true;
        lclSelectedProfileCalculation =
          lclSelectedCalculation.profileCalculations[0];

        lclCurrentProfileCalculationResults = await getCalculationProfiles(
          lclSelectedCalculation,
          lclSelectedProfileCalculation,
        );

        const constructionDetailsResponse = await getConstructionDetails(
          lclSelectedCalculation.calculationId,
        );

        lclConstructionDetails =
          constructionDetailsResponse.data?.calculationConstructionDetails;
        lclCurrentMastNo =
          lclCurrentProfileCalculationResults?.mastResults !== undefined &&
          lclCurrentProfileCalculationResults?.mastResults.length > 0
            ? lclCurrentProfileCalculationResults?.mastResults[0]?.mastNumber
            : undefined;
      }
    }

    if (id !== undefined) {
      dispatch(loadConstructions(constructions));
      dispatch(
        loadCurrentConstruction({
          currentConstruction,
          all: all.length > 0 ? all : constructions,
          defaultCalculationParameters: null,
        }),
      );
      dispatch(
        loadCalculation({
          currentCalculationId: lclSelectedCalculation?.calculationId,
          currentProfileCalculationResults: lclCurrentProfileCalculationResults,
          constructionDetails: lclConstructionDetails,
          calculations: lclCalculations,
          currentMastNo: lclCurrentMastNo,
          selectedProfileCalculationId:
            lclSelectedProfileCalculation?.profileCalculationId,
        }),
      );
    }
  };

  const handleSaveModifiedConstruction = async (): Promise<void> => {
    await saveModifiedConstruction(
      id,
      selectedCalculationResults.calculationId as string,
      selectedCalculationResults.mastResults,
      selectedCalculationResults.editableConstructionProperties,
    );
  };

  return {
    isAllowToTakeAction: user?.isAllowedToTakeAction() && isConstructionActive,
    calculations,
    selectedMastNo,
    selectedCalculationResults,
    constructionDetails,
    construction,
    messages,
    loadPageData,
    handleSaveModifiedConstruction,
  };
};

export default useConstructionCalculationsPageHook;
