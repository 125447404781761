import CalculationStatus from "../../../../types/CalculationStatus";
import styles from "./CalculationsPanelProfileCalculationItem.module.scss";

const useCalculationsPanelProfileCalculationItem = (
  status: CalculationStatus,
): {
  className: string;
  iconName: string;
} => {
  if (
    status === CalculationStatus.Failed ||
    status === CalculationStatus.NoValidProfilesFound
  ) {
    return {
      iconName: "ErrorBadge",
      className: styles.calculationProfileStatusFailed,
    };
  }

  if (
    status === CalculationStatus.Pending ||
    status === CalculationStatus.Created
  ) {
    return {
      iconName: "Info",
      className: styles.calculationProfileStatus,
    };
  }

  if (
    status === CalculationStatus.Completed ||
    status === CalculationStatus.ValidProfilesFound
  ) {
    return {
      iconName: "Completed",
      className: styles.calculationProfileStatusCompleted,
    };
  }

  return {
    iconName: "Info",
    className: styles.calculationProfileStatusPartially,
  };
};

export default useCalculationsPanelProfileCalculationItem;
