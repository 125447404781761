import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox} from "@fluentui/react";
import type {IEditableItemProps} from "./IEditableItemProps";
import {EditableType} from "../../../types/EditableItem";

import Select from "./Select";
import Editable from "./Editable";
import {removeMirrorFromValue} from "../../../services/editableConstruction/EditableConstructionService";
import {updateEditableProps} from "../../../pages/construction/constructionSlice";
import useEditableHook from "../../../hooks/useEditableHook";
import type {RootState} from "../../../store";

const EditableItem: React.FunctionComponent<IEditableItemProps> = ({
  value,
  entity: entityName,
  type,
  entityId,
  property: propertyName,
  extraInfo,
  isDisabled,
}) => {
  const [newValue, SetNewValue] = useState<string>("");
  const [isValid, SetIsValid] = useState<boolean>(true);
  const {validateConstructionPropertyChanged} = useEditableHook();
  const {defaultCalculationParams} = useSelector(
    (state: RootState) => state.construction,
  );
  const dispatch = useDispatch();

  const validateCalculationDeformationParameters = (): boolean => {
    const defaultDeformation =
      defaultCalculationParams?.deformationParameters.find(
        df => df.id.toLocaleLowerCase() === propertyName.toLocaleLowerCase(),
      );

    if (defaultDeformation !== undefined) {
      const isAboveMin =
        defaultDeformation.min === null ||
        Number(newValue) >= defaultDeformation.min;

      const isBelowMax =
        defaultDeformation.max === null ||
        Number(newValue) <= defaultDeformation.max;
      return isAboveMin && isBelowMax;
    }
    return false;
  };
  const validateProperty = async (): Promise<void> => {
    let validated: boolean | null;
    // Note: validate calculation parameters first on the FE. BE will also have validation in place.
    if (
      propertyName.toLocaleLowerCase().includes("deformation") ||
      propertyName.toLocaleLowerCase().includes("overdimensioning")
    ) {
      validated = validateCalculationDeformationParameters();
    } else {
      validated = await validateConstructionPropertyChanged(
        entityName,
        propertyName,
        entityId,
        removeMirrorFromValue(newValue),
      );
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!validated) {
      SetIsValid(false);
    } else {
      SetIsValid(true);

      dispatch(
        updateEditableProps({
          entityName,
          entityId: entityId ?? "",
          propertyName,
          propertyValue: newValue,
        }),
      );
    }
  };

  const handleValueChanged = (newValue: any): void => {
    SetNewValue(newValue as string);
  };

  useEffect(() => {
    SetNewValue(value);
  }, []);

  const handleOnBlur = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    validateProperty();
  };

  const selectComponent = (type: EditableType, isDisabled: boolean): any => {
    switch (type) {
      case EditableType.Select:
        return (
          <Select
            isDisabled={isDisabled}
            extraInfo={extraInfo}
            value={newValue}
            module={entityName}
            property={propertyName}
            onValueChanged={handleValueChanged}
          />
        );
      case EditableType.Number:
        return (
          <input
            value={newValue}
            type="number"
            disabled={isDisabled}
            onChange={e => {
              handleValueChanged(e.target.value);
            }}
          />
        );
      case EditableType.Text:
        return (
          <input
            value={newValue}
            disabled={isDisabled}
            type="text"
            onChange={e => {
              handleValueChanged(e.target.value);
            }}
          />
        );
      // @typescript-eslint\no-unused-vars
      case EditableType.CheckBox:
        return (
          <Checkbox
            disabled={isDisabled}
            checked={Boolean(newValue)}
            onChange={(ev, data) => {
              handleValueChanged(data);
            }}
          />
        );
      default:
        return newValue;
    }
  };

  return (
    <Editable
      isDisabled={isDisabled}
      value={newValue}
      onValidateInput={handleOnBlur}
      type={type}
      isValid={isValid}>
      {selectComponent(type, isDisabled)}
    </Editable>
  );
};

export default EditableItem;
