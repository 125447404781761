import React from "react";
import type IsaacBadgeProps from "./IsaacBadgeProps";

const IsaacBadge: React.FunctionComponent<IsaacBadgeProps> = ({
  isVisible,
  className,
  title,
  children,
  handleClick,
}) => {
  return (
    <>
      {isVisible && (
        <div className={className} title={title} onClick={handleClick}>
          {children}
        </div>
      )}
    </>
  );
};

IsaacBadge.displayName = "IsaacBadge";

export default IsaacBadge;
