import {useContext} from "react";
import type IAuthContext from "../types/IAuthContext";
import {AuthContext} from "../AuthContext";

export const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext);

  if (context == null) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};
