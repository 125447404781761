import React from "react";
import styles from "./IsaacEmpty.module.scss";

import type IsaacEmptyProps from "./IsaacEmptyProps";

const IsaacEmpty: React.FunctionComponent<IsaacEmptyProps> = ({message}) => {
  return (
    <div className={styles.emptyList}>
      <img
        className={styles.emptyListImage}
        src={`${process.env.PUBLIC_URL}/images/empty-new-dev.svg`}
      />
      {message !== undefined && <p>{message}</p>}
    </div>
  );
};

export default IsaacEmpty;
