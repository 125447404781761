export const addToLocaLStorage = <T>(key: string, value: T): void => {
  const existingValue = localStorage.getItem(key);

  if (existingValue !== null) {
    localStorage.removeItem(key);
  }

  localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLocalStorage = <T>(key: string): T | null => {
  const value = localStorage.getItem(key);

  if (value !== null) {
    return JSON.parse(value) as T;
  }

  return null;
};

export const removeFromLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};
