import React from "react";
import {Stack} from "@fluentui/react";

import styles from "./DeselectedElementsPanel.module.scss";
import IsaacList from "../../../../components/isaacList/IsaacList";
import type DeselectedElementsPanelProps from "./DeselectedElementsPanelProps";
import type RenderableDeselectedConstructionElement from "../../../../types/RenderableDeselectedConstructionElement";

const DeselectedElementsPanel: React.FunctionComponent<
  DeselectedElementsPanelProps
> = ({items}) => {
  return (
    <>
      {items.length > 0 && (
        <Stack grow={1}>
          {/* TODO: Move all text into translation files */}
          <h3 className={styles.header}>Deselected construction elements</h3>
          <IsaacList<RenderableDeselectedConstructionElement>
            items={items}
            onRenderCell={item => (
              <>{`${item?.entityType}(${item?.compartment},${item?.position})`}</>
            )}
          />
        </Stack>
      )}
    </>
  );
};

export default DeselectedElementsPanel;
