import React, {useEffect, useState} from "react";
import {Text, Separator, TooltipHost, Icon} from "@fluentui/react";
import {useId} from "@fluentui/react-hooks";

import "../../custom-toastify.css";
import styles from "./IsaacToast.module.scss";
import type IsaacToastProps from "./IsaacToastProps";

// Note: all text should be part of a translation file
const IsaacToast: React.FunctionComponent<IsaacToastProps> = ({
  message,
  additionalMessage,
  toastType,
  correlationId,
  statusCode,
  traceId,
  extraInfo,
}) => {
  const tooltipId = useId("tooltip");
  const [copied, setCopied] = useState<boolean>(false);
  const showTechnicalDetails = toastType === "error" && traceId !== undefined;
  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(prevState => !prevState);
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [copied]);

  return (
    <>
      <Text>{message}</Text>
      {showTechnicalDetails && (
        <>
          <Separator>Technical details</Separator>
          <Text block>
            <b>{`Message: `}</b>
            {additionalMessage}
          </Text>
          <Text block>
            <b>{`Status code: `}</b>
            {statusCode}
          </Text>
          <Text block>
            <b>{`Trace ID: `}</b>
            {traceId}
          </Text>
          <Text>
            <b>{`Correlation ID: `}</b>
            <TooltipHost
              content="Click on the id to copy it to clipboard."
              id={tooltipId}
              calloutProps={{gapSpace: 0}}>
              <Text
                aria-describedby={tooltipId}
                className={styles.isaacToastClickableText}
                onClick={e => {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  navigator.clipboard.writeText(e.currentTarget.outerText);
                  setCopied(prevState => !prevState);
                }}>
                {correlationId}
              </Text>
            </TooltipHost>
            {copied && (
              <Icon
                iconName="SkypeCheck"
                className={styles.isaacToastCopiedCheckIcon}
              />
            )}
          </Text>
          {extraInfo !== undefined && (
            <>
              {Array.from(extraInfo.entries()).map(
                ([key, value]: [string, string]) => (
                  <Text key={key} block>
                    <b>{`${key}: `}</b>
                    {value}
                  </Text>
                ),
              )}
            </>
          )}
          <Text variant="xSmall" className={styles.isaacToastFooter}>
            Click on the `Correlation ID` to copy it and send it to the support
            desk.
          </Text>
        </>
      )}
    </>
  );
};

export default IsaacToast;
