import React, {createContext} from "react";
import {useSelector} from "react-redux";

import type ApiContextValue from "./types/ApiContextValue";
import type ApiContextProps from "./types/ApiContextProps";
import type {RootState} from "../../store";
import fetchData from "./ApiService";

export const ApiContext = createContext<ApiContextValue | undefined>(undefined);

export const ApiProvider: React.FC<ApiContextProps> = ({children}) => {
  const {tokens} = useSelector((state: RootState) => state.userInfo);

  const value: ApiContextValue = {
    fetchData,
    tokens,
    loading: false,
  };

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};
