import React from "react";
import {useDispatch, useSelector} from "react-redux";

import styles from "./Table.module.scss";
import TranslatedText from "../translatedText/TranslatedText";
import TableRow from "./row/TableRow";
import type ITableProps from "./ITableProps";
import type {RootState} from "../../../store";
import type SelectedEntity from "../../../types/SelectedEntity";
import {updateSelectedEntities} from "../../../pages/construction/constructionSlice";

const Table: React.FunctionComponent<ITableProps> = ({
  columns,
  values,
  name,
  parent,
  count,
  tableId,
  constructionId,
  isAllowToTakeAction,
}: ITableProps) => {
  const dispatch = useDispatch();

  const columnsWithoutId = columns.filter(
    c => c !== "id" && c !== "selectionId",
  );

  const {deselectedEntities} = useSelector(
    (state: RootState) => state.construction,
  );

  const handleToggleSwitch = (value: any): void => {
    const entity: SelectedEntity = {
      entityId: value.selectionId,
      entityType: parent ?? name ?? "",
      compartment: value.compartment,
      position: value.position,
    };
    dispatch(updateSelectedEntities(entity));
  };

  const checkIsDisabled = (value: any, entityType: string): boolean =>
    deselectedEntities?.find(
      d => d.entityType === entityType && d.entityId === value?.selectionId,
    ) === undefined;

  return (
    <div id={tableId} className={styles.constructionDataItem}>
      {(values.length > 0 || count === null) && (
        <div>
          <div className={styles.resultTableContainer}>
            <table className={styles.resultTable}>
              <thead>
                <tr className={styles.headers}>
                  {columnsWithoutId.map((column, i) => (
                    <th key={i}>
                      <TranslatedText value={column} parent={name ?? parent} />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {values.length > 0 &&
                  values.map((value, i) => (
                    <TableRow
                      key={i}
                      id={i}
                      data={columnsWithoutId}
                      className={styles.result}
                      value={value}
                      name={name}
                      constructionId={constructionId}
                      isAllowToTakeAction={isAllowToTakeAction}
                      handleToggleSwitch={() => {
                        handleToggleSwitch(value);
                      }}
                      isDisabled={checkIsDisabled(value, parent ?? name ?? "")}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

Table.displayName = "Table";

export default Table;
