import type IFilters from "../types/IFilters";
import {formatDateStringToDate} from "./DateFormatting";

export const getPaginatedData = <T>(
  data: T[],
  pageSize: number,
  currentIndex: number,
): T[] => {
  const {fromIndex, toIndex} = calculationFromToIndex(
    data.length,
    pageSize,
    currentIndex,
  );

  return data.slice(fromIndex, toIndex);
};

// Note: Needs refactoring - remove magic strings and make more generic
export const filterData = <T>(
  data: T[] | undefined,
  filters: IFilters,
): T[] | undefined => {
  const result = data?.filter((item: T | any) =>
    Object.entries(filters).every(([key, value]) => {
      if (value !== undefined && value.length > 0) {
        if (key === "from") {
          return (
            formatDateStringToDate(item.createdOn as string) >=
            formatDateStringToDate(value as string)
          );
        }
        if (key === "to") {
          return (
            formatDateStringToDate(item.createdOn as string) <=
            formatDateStringToDate(value as string)
          );
        }
        if (key === "tags") {
          return item[key].some((tag: string) => value.includes(tag));
        }

        if (key === "locationCodes") {
          return value.includes(item.locationCode);
        }

        if (key === "createdBy") {
          return value.includes(item.createdBy);
        }

        return item[key].toLowerCase().includes(value.toLowerCase());
      }

      return true;
    }),
  );
  return result;
};

export const filterDataWithPagination = <T>(
  data: T[] | undefined,
  filters: IFilters,
  pageSize: number,
  currentIndex: number,
): T[] | undefined => {
  const filtered = filterData(data, filters);

  if (filtered !== undefined) {
    return getPaginatedData(filtered, pageSize, currentIndex);
  }

  return filtered;
};

const calculationFromToIndex = (
  total: number,
  pageSize: number,
  currentIndex: number,
): {fromIndex: number; toIndex: number} => {
  const fromIndex = currentIndex * pageSize;
  const toIndex = Math.min((currentIndex + 1) * pageSize, total);

  return {fromIndex, toIndex};
};
