import React from "react";

import {Icon} from "@fluentui/react";
import styles from "./IsaacCalculationBadges.module.scss";

import IsaacBadge from "../isaacBadge/IsaacBadge";
import CalculationStatus from "../../types/CalculationStatus";
import type IsaacCalculationBadgesProps from "./IsaacCalculationBadgesProps";

// Note: This should be rethought to be agnostic from a functionality perspective
const IsaacCalculationBadges: React.FunctionComponent<
  IsaacCalculationBadgesProps
> = ({
  status,
  isSelected,
  isAllowToTakeAction,
  handleCalculationStop,
  handleCalculationDelete,
}) => {
  return (
    <div className={styles.actionsContainer}>
      <IsaacBadge
        isVisible={
          (status === CalculationStatus.Pending ||
            status === CalculationStatus.Created) &&
          isAllowToTakeAction
        }
        className={`${styles.action} ${styles.stop}`}
        title="Stop"
        handleClick={handleCalculationStop}>
        <Icon iconName="Stop" />
      </IsaacBadge>
      <IsaacBadge
        isVisible={
          status !== CalculationStatus.Pending &&
          !isSelected &&
          isAllowToTakeAction
        }
        className={`${styles.action} ${styles.delete}`}
        title="Delete"
        handleClick={handleCalculationDelete}>
        <Icon iconName="Delete" />
      </IsaacBadge>
    </div>
  );
};

IsaacCalculationBadges.displayName = "IsaacCalculationBadges";

export default IsaacCalculationBadges;
