import React from "react";
import {Panel, PanelType} from "@fluentui/react";

import styles from "./ConstructionCalculationsPanel.module.scss";
import type ConstructionCalculationsPanelProps from "./ConstructionCalculationsPanelProps";
import CalculationsPanelList from "../sidePanelList/CalculationsPanelList";

const CalculationsPanel: React.FunctionComponent<
  ConstructionCalculationsPanelProps
> = ({open, calculations, isAllowToTakeAction, setCalculationPanelState}) => {
  return (
    <Panel
      onDismiss={() => {
        setCalculationPanelState();
      }}
      type={PanelType.customNear}
      closeButtonAriaLabel="Close"
      isOpen={open}
      isLightDismiss
      isBlocking={true}
      customWidth="350px"
      className={styles.leftSideBar}>
      <div className={styles.calculationsContainer}>
        <CalculationsPanelList
          calculations={calculations}
          isAllowToTakeAction={isAllowToTakeAction}
        />
      </div>
    </Panel>
  );
};

export default CalculationsPanel;
