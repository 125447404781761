import {useState} from "react";
import {type IObjectWithKey, Selection} from "@fluentui/react";

const useConstructionTableSelectionHook = (
  handleSelection: (selectedConstructions: any[]) => void,
): Selection<IObjectWithKey> => {
  const [selection] = useState<Selection>(
    new Selection({
      onSelectionChanged: () => {
        const selected = selection.getSelection();
        const updatedConstructions = [...selected];

        handleSelection(updatedConstructions as any[]);
      },
    }),
  );

  return selection;
};

export default useConstructionTableSelectionHook;
