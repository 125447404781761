import {useNavigate, useParams} from "react-router-dom";
import {v4 as uuidv4} from "uuid";

import useConstructionHook from "../../hooks/useConstructionHook";
import useCalculationHook from "../../hooks/useCalculationHook";
import {deleteConstructions} from "../../pages/constructions/constructionsSlice";
import {clear} from "../../pages/construction/constructionSlice";
import {useDispatch} from "react-redux";
import PathConstants from "../../routes/pathConstants";
import useIssacToastHook from "../../hooks/useIssacToastHook";
import IsaacToastWrapperType from "../../types/IsaacToastWrapperType";
import type ApiResponse from "../../services/api/types/ApiResponse";

const useIsaacDeleteConstructionHook = (
  handleDialogDismiss: React.Dispatch<React.SetStateAction<boolean>>,
): {
  handleDelete: () => Promise<void>;
} => {
  const {deleteConstructionById} = useConstructionHook();
  const {deleteCalculationsByConstructionId} = useCalculationHook();

  const {id} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {withToast} = useIssacToastHook();

  const handleDelete = async (): Promise<void> => {
    handleDialogDismiss(true);
    if (id !== undefined) {
      const correlationId: string = uuidv4();

      await withToast(async () => {
        const clResponse = await deleteCalculationsByConstructionId(
          id,
          correlationId,
        );

        if (!clResponse.success) {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          return {success: false} as ApiResponse<any>;
        }

        const ctResponse = await deleteConstructionById(id, correlationId);
        if (!ctResponse.success) {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          return {success: false} as ApiResponse<any>;
        }

        setTimeout(() => {
          dispatch(deleteConstructions([id]));
          dispatch(clear());
          navigate(PathConstants.Home);
        }, 2000);

        return ctResponse;
      }, IsaacToastWrapperType.Full);
    }
  };

  return {handleDelete};
};

export default useIsaacDeleteConstructionHook;
