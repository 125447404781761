import React, {useState} from "react";
import styles from "../../Construction.module.scss";
import {IconButton} from "@fluentui/react";

import type ITagsProps from "./IConstructionTagsProps";
import ConfirmTagDeletionDialog from "../../../../components/isaacTagsDialogs/deletion/ConfirmTagDeletionDialog";
import AddTagsDialog from "../../../../components/isaacTagsDialogs/addition/AddTagsDialog";

const ConstructionTags: React.FunctionComponent<ITagsProps> = ({
  construction,
  isAllowToTakeAction,
}) => {
  const {tags}: {tags: string[]} = construction;
  const [isDeleteDialogHidden, setIsDeleteDialogHidden] =
    useState<boolean>(true);
  const [isAddDialogHidden, setIsAddDialogHidden] = useState<boolean>(true);
  const [tagToDelete, setTagToDelete] = useState<string>();

  const handleAddDialogDismiss = (): void => {
    setIsAddDialogHidden(true);
  };

  const handleDeleteDialogDismiss = (): void => {
    setIsDeleteDialogHidden(true);
  };

  // Note: Same code used here and in Calculation. Make this single re-usable component
  return (
    <>
      <ul className={styles.tagsContainer}>
        {tags?.map((tag, index) => {
          return (
            isAllowToTakeAction && (
              <li className={styles.tag} key={index}>
                <span className={styles.name}>{tag}</span>
                <span className={styles.removeBtn}>
                  <IconButton
                    iconProps={{iconName: "Clear"}}
                    title="Remove"
                    ariaLabel="Remove"
                    onClick={() => {
                      setTagToDelete(tag);
                      setIsDeleteDialogHidden(false);
                    }}
                  />
                </span>
              </li>
            )
          );
        })}
        {isAllowToTakeAction && (
          <li key="add" className={styles.tag} style={{borderRadius: "2px"}}>
            <span className={styles.addBtn}>
              <IconButton
                iconProps={{iconName: "Add"}}
                title="Add new tag"
                ariaLabel="Add new tag"
                onClick={() => {
                  setIsAddDialogHidden(false);
                }}
              />
            </span>
          </li>
        )}
      </ul>
      <ConfirmTagDeletionDialog
        isHidden={isDeleteDialogHidden}
        handleDismiss={handleDeleteDialogDismiss}
        tag={tagToDelete}
        constructionId={construction.id}
      />

      <AddTagsDialog
        isHidden={isAddDialogHidden}
        handleDismiss={handleAddDialogDismiss}
      />
    </>
  );
};

export default ConstructionTags;
