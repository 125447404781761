import React from "react";
import {Link, Text} from "@fluentui/react";
import {toast} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";

import type {SignalRMessage} from "../../../services/signalR/models/Messages";

import {removeFromLocalStorage} from "../../../utils/ManageLocalStorage";
import PathConstants from "../../../routes/pathConstants";

const useCalculationCompleteHandler = (): ((
  message: SignalRMessage,
) => void) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userIsOnCalculationsPage = location.pathname
    .split("/")
    .some(s => s === PathConstants.Calculations);
  const execute = (message: SignalRMessage): void => {
    removeFromLocalStorage(`${message.data.constructionId}.inProgress`);
    const calculationUrl = `${PathConstants.Constructions}/${message.data.constructionId}/${PathConstants.Calculations}`;

    toast(
      <>
        {userIsOnCalculationsPage && (
          <Text>
            Calculation completed. Your data was automatically refreshed.
          </Text>
        )}
        {!userIsOnCalculationsPage && (
          <>
            {" "}
            <Text>
              Click{" "}
              <Link
                onClick={() => {
                  if (calculationUrl === location.pathname) {
                    navigate(0);
                  } else {
                    navigate(calculationUrl);
                  }
                }}>
                here
              </Link>{" "}
              to navigate to calculation.
            </Text>
          </>
        )}
      </>,
      {toastId: message.data.calculationId},
    );
  };
  return execute;
};

export default useCalculationCompleteHandler;
