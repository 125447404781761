import React from "react";
import {toast} from "react-toastify";

import type ApiResponse from "../services/api/types/ApiResponse";
import IsaacToast from "../components/isaacToast/IsaacToast";
import IsaacToastWrapperType from "../types/IsaacToastWrapperType";

/**
 * Hook that provides a way to wrap calls to the BE and provide a uniform way to handle states: inProgress, Success, Error.
 */
const useIssacToastHook = (): {
  /**
   * Wrapper function for api calls.
   */
  withToast: <T>(
    func: () => Promise<ApiResponse<T>>,
    isaacToastWrapperType?: IsaacToastWrapperType,
    extraInfo?: Map<string, string> | undefined,
    inProgressMessage?: string,
    successMessage?: string,
    errorMessage?: string,
  ) => Promise<ApiResponse<T>>;
} => {
  /**
   * Standard wrapper meant for call where you do not need to see loading or success toast.
   */
  const withErrorToast = async <T = Promise<ApiResponse<any>>,>(
    func: () => Promise<ApiResponse<T>>,
    extraInfo?: Map<string, string> | undefined,
    errorMessage: string = "Failed to execute action/s 🤯",
  ): Promise<ApiResponse<T>> => {
    const result = await func();

    if (result.success !== undefined && !result.success) {
      toast(
        <IsaacToast
          message={errorMessage}
          toastType="error"
          additionalMessage={result.message}
          correlationId={result.correlationId}
          traceId={result.traceId}
          statusCode={result.statusCode}
          extraInfo={extraInfo}
        />,
        {isLoading: false, autoClose: 3000, type: "error"},
      );
    }

    return result;
  };

  const withToast = async <T = Promise<ApiResponse<any>>,>(
    func: () => Promise<ApiResponse<T>>,
    isaacToastWrapperType: IsaacToastWrapperType = IsaacToastWrapperType.OnlyError,
    extraInfo?: Map<string, string> | undefined,
    inProgressMessage: string = "Execution action/s...",
    successMessage: string = "Action/s executed successfully 👌",
    errorMessage: string = "Failed to execute action/s 🤯",
  ): Promise<ApiResponse<T>> => {
    if (isaacToastWrapperType === IsaacToastWrapperType.OnlyError) {
      return await withErrorToast(func, extraInfo);
    }

    const toastId = toast.loading(inProgressMessage);

    const result = await func();

    const isSuccess = checkResponse<T>(result);

    toast.update(toastId, {
      render: (
        <IsaacToast
          message={isSuccess ? successMessage : errorMessage}
          additionalMessage={isSuccess ? "" : result.message}
          toastType={isSuccess ? "success" : "error"}
          correlationId={result.correlationId}
          traceId={result.traceId}
          statusCode={result.statusCode}
          extraInfo={extraInfo}
        />
      ),
      type: isSuccess ? "success" : "error",
      isLoading: false,
      autoClose: 3000,
    });

    return result;
  };

  const checkResponse = <T,>(apiResponse: ApiResponse<T>): boolean => {
    const isRequestSuccessful = Array.isArray(apiResponse)
      ? apiResponse.every(r => r.success)
      : apiResponse.success;

    if (typeof apiResponse.data === "boolean")
      return isRequestSuccessful && apiResponse.data;

    return isRequestSuccessful;
  };

  return {withToast};
};

export default useIssacToastHook;
