import React, {useEffect, useState} from "react";
import {Icon} from "@fluentui/react";
import {Link} from "react-router-dom";
import moment from "moment";
import styles from "../../Construction.module.scss";
import headerStyles from "./ConstructionHeader.module.scss";

import Table from "../../../../components/_legacy/table/Table";
import ConstructionTags from "../tags/ConstructionTags";
import ConstructionNavigation from "../navigation/ConstructionNavigation";
import ConstructionCommandBar from "../commandBar/ConstructionCommandBar";
import SrvCore from "../../../../utils/_legacy/SrvCore";
import {useAuth} from "../../../../services/auth/hooks/useAuth";
import type IConstructionHeaderProps from "./ConstructionHeaderProps";
import ConstructionStatus from "../../../../types/ConstructionStatus";
import PathConstants from "../../../../routes/pathConstants";

const ConstructionHeader: React.FunctionComponent<IConstructionHeaderProps> = ({
  construction,
}) => {
  const [infoBarData, setInfoBarData] = useState<any>();
  const {user} = useAuth();
  const isAllowedToTakeAction = user?.isAllowedToTakeAction();
  const isConstructionFrozen =
    construction?.status === ConstructionStatus.Frozen;
  const updatedOn = moment(construction?.sourceModificationTimeStamp).format(
    "DD/MM/YYYY HH:MM",
  );

  useEffect(() => {
    if (construction !== undefined) {
      const data = SrvCore.getConstructionGeneralData(construction);
      setInfoBarData(data);

      const calculationParameters =
        SrvCore.getConstructionCalculationParametersData(construction);
      setInfoBarData([...data, ...calculationParameters]);
    }
  }, [construction]);

  return (
    <div key={construction?.id} className={styles.header}>
      <div className={styles.panelHeader}>
        <div className={styles.panelHeaderContainer}>
          <div className={headerStyles.title}>
            <h2 className={isConstructionFrozen ? styles.isFrozen : ""}>
              {construction?.name}
              {isConstructionFrozen && (
                <Icon
                  iconName="Snowflake"
                  className={styles.frozenIsFrozen}
                  title="Construction is frozen. No changes can be performed."
                />
              )}
            </h2>
            {construction?.sourceCalculationId !== null &&
              construction?.sourceModificationTimeStamp !== null && (
                <Link
                  state={{calculationId: construction?.sourceCalculationId}}
                  to={`${PathConstants.Constructions}/${construction?.id}/${PathConstants.Calculations}`}>
                  <h3
                    className={
                      headerStyles.updatedOn
                    }>{`Updated on ${updatedOn}`}</h3>
                </Link>
              )}

            {construction !== undefined && (
              <ConstructionTags
                isAllowToTakeAction={isAllowedToTakeAction}
                construction={construction}
              />
            )}
          </div>
          <ConstructionNavigation />
        </div>
      </div>
      <div className={styles.infoBar}>
        {infoBarData?.map((data: any) => (
          <Table
            key={data?.id}
            columns={data.columns}
            values={data.items}
            tableId={data?.id}
            parent="general"
            name={data.name}
            constructionId={construction?.id}
            isAllowToTakeAction={isAllowedToTakeAction && !isConstructionFrozen}
          />
        ))}
      </div>
      <ConstructionCommandBar
        constructionStatus={construction?.status}
        isAllowToTakeAction={isAllowedToTakeAction}
      />
    </div>
  );
};

export default ConstructionHeader;
