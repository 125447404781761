import React from "react";
import styles from "./TableCell.module.scss";
import TranslatedText from "../../translatedText/TranslatedText";
import type ITableCellProps from "./ITableCellProps";
import {useTranslation} from "react-i18next";

// Note: Extend table cell to allow to render props and to be used in header and body
const TableCell: React.FunctionComponent<ITableCellProps> = ({
  id,
  value,
  toolTip,
  column,
  isDisabled,
}: ITableCellProps) => {
  // Note: Temporary solution should not be the responsibility of the cell component to do translations
  const translatedValueForConnectedToOnly = (value: string): any => {
    if (
      column === "connectedTo" &&
      value !== undefined &&
      value !== "" &&
      value !== null
    ) {
      const {t} = useTranslation();
      const delimiter = "(";
      const index = value.indexOf(delimiter);
      const translatableString = value.substring(0, index);
      const restOfString = value.substring(index, value.length);

      return `${t(`connectedTo_${translatableString}`)}${restOfString}`;
    }
    return value;
  };

  return (
    <td key={id} className={isDisabled ? styles.cellDisabled : styles.normal}>
      {toolTip !== undefined && toolTip !== "" ? (
        <TranslatedText value={value} tooltipValue={toolTip} />
      ) : (
        translatedValueForConnectedToOnly(value)
      )}
    </td>
  );
};

TableCell.displayName = "TableName";
export default TableCell;
