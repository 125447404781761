import {createSlice} from "@reduxjs/toolkit";
import type {PayloadAction} from "@reduxjs/toolkit";
import type AuthToken from "./types/IAuthToken";
import Language from "../../types/Language";

interface UserInfoState {
  roles: string[] | undefined;
  email: string | undefined;
  userName: string | undefined;
  tokens: AuthToken[] | undefined;
  language: string | undefined;
}

const initialState: UserInfoState = {
  tokens: undefined,
  email: undefined,
  roles: undefined,
  userName: undefined,
  language: Language.ACAD,
};

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    updateAuthTokens: (
      state,
      {payload}: PayloadAction<AuthToken[] | undefined>,
    ) => {
      state.tokens = payload;
    },
    updateUserInfo: (
      state,
      {
        payload: {userName, roles, email},
      }: PayloadAction<{
        userName: string | undefined;
        roles: string[] | undefined;
        email: string | undefined;
      }>,
    ) => {
      state.userName = userName;
      state.roles = roles;
      state.email = email;
    },
    changeLanguage: (state, {payload}: PayloadAction<string>) => {
      state.language = payload;
    },
  },
});

export const {updateAuthTokens, updateUserInfo, changeLanguage} =
  userInfoSlice.actions;

export default userInfoSlice.reducer;
