import type {Configuration} from "@azure/msal-browser";

const msalConfig: Configuration = {
  auth: {
    clientId: `${process.env.REACT_APP_AAD_CLIENT_ID}`,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AAD_AUTHORITY}`,
    redirectUri: `/`,
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

export default msalConfig;
