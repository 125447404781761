import * as React from "react";
import {useTranslation} from "react-i18next";
import {useId} from "@fluentui/react-hooks";
import {type ITooltipHostStyles, TooltipHost} from "@fluentui/react";
import type ITranslatedText from "./ITranslatedTextProps";

const calloutProps = {gapSpace: 0};
const hostStyles: Partial<ITooltipHostStyles> = {
  root: {display: "inline-block"},
};

const TranslatedText: React.FunctionComponent<ITranslatedText> = ({
  value,
  parent,
  showTooltip,
  tooltipValue,
}: ITranslatedText) => {
  const {t, i18n} = useTranslation();
  const tooltipId = useId("tooltip");

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!showTooltip && !parent) {
    return <>{t(value)} </>;
  }

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!showTooltip && parent) {
    return <>{t(value, {context: parent})} </>;
  }

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (showTooltip) {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (tooltipValue) {
      return (
        <TooltipHost
          content={tooltipValue}
          id={tooltipId}
          calloutProps={calloutProps}
          styles={hostStyles}>
          {t(value)}
        </TooltipHost>
      );
    }

    let reverseLanguage = "en";

    if (i18n.language === "en") {
      reverseLanguage = "code";
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!parent) {
      return (
        <TooltipHost
          content={t(value, {lng: reverseLanguage})}
          id={tooltipId}
          calloutProps={calloutProps}
          styles={hostStyles}>
          {t(value)}
        </TooltipHost>
      );
    }
    return (
      <TooltipHost
        content={t(value, {context: parent, lng: reverseLanguage})}
        id={tooltipId}
        calloutProps={calloutProps}
        styles={hostStyles}>
        {t(value, {context: parent})}
      </TooltipHost>
    );
  }

  return <div>{t(value)}</div>;
};

TranslatedText.defaultProps = {
  showTooltip: true,
};

TranslatedText.displayName = "TranslatedText";

export default TranslatedText;
