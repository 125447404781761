import "./Login.module.scss";
import React from "react";

import {Navigate} from "react-router-dom";
import {Modal, CompoundButton} from "@fluentui/react";

import PathConstants from "../../routes/pathConstants";
import {useAuth} from "../../services/auth/hooks/useAuth";

const Login: React.FunctionComponent = () => {
  const {isAuthenticated, signIn} = useAuth();

  if (isAuthenticated) {
    return <Navigate to={PathConstants.Home} replace={true} />;
  }

  return (
    <Modal
      className="isaac-login"
      isAlert={true}
      isBlocking={true}
      isOpen={true}
      styles={{
        root: {
          selectors: {
            "[id^='ModalFocusTrapZone']": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            "[id^='ModalFocusTrapZone'] .ms-Modal-scrollableContent": {
              display: "flex",
              justifyContent: "center",
            },
          },
        },
        main: {
          selectors: {
            ".ms-Modal-scrollableContent": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          },
        },
      }}>
      <CompoundButton
        primary
        iconProps={{iconName: "AzureLogo"}}
        secondaryText="User AAD account to login."
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          (async () => {
            await signIn();
          })();
        }}>
        LogIn
      </CompoundButton>
    </Modal>
  );
};

export default Login;
