import React, {type ReactNode} from "react";
import {ErrorBoundary} from "react-error-boundary";

import ErrorFallback from "./ErrorFallback";

const IsaacErrorBoundary: React.FunctionComponent<{children: ReactNode}> = ({
  children,
}) => {
  return (
    <ErrorBoundary
      onError={() => {
        console.log("Error in boundary");
      }}
      FallbackComponent={ErrorFallback}>
      {children}
    </ErrorBoundary>
  );
};

export default IsaacErrorBoundary;
