import React, {useEffect, useState} from "react";

import styles from "../../Construction.module.scss";
import SrvCore from "../../../../utils/_legacy/SrvCore";
import ConstructionDetailsSection from "../detailsSection/ConstructionDetailsSection";
import type ConstructionBodyProps from "./ConstructionBodyProps";
import type {ConstructionGUIData} from "../../../../types/ConstructionGUIData";
import {useNavigate} from "react-router-dom";
import PathConstants from "../../../../routes/pathConstants";

const ConstructionBody: React.FunctionComponent<ConstructionBodyProps> = ({
  construction,
}) => {
  // Note: refactor source data propagation to not use state
  const [data, setData] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    if (construction === undefined || construction === null) {
      navigate(PathConstants.NotFound);
    } else {
      const constructionData = SrvCore.getConstructionData(construction);
      setData(constructionData);
    }
  }, [construction]);

  return (
    <>
      {data !== undefined && (
        <div className={styles.panelContainer}>
          <div className="ms-slideRightIn40" dir="ltr">
            <div>
              {data
                .filter((s: any) => s.parent === null)
                .map((section: ConstructionGUIData) => (
                  <ConstructionDetailsSection
                    key={section?.id}
                    section={section}
                    constructionId={construction.id}
                    constructionStatus={construction.status}
                    subSections={data.filter(
                      (s: any) => s.parent === section?.id,
                    )}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConstructionBody;
