import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import type IConstruction from "../types/IConstruction";
import type {RootState} from "../../../store";
import {load} from "../constructionSlice";
import {load as loadConstructions} from "../../../pages/constructions/constructionsSlice";
import {getOrderByCreatedDate} from "../../../utils/DateFormatting";
import useConstructionHook from "../../../hooks/useConstructionHook";

const useConstructionPageHook = (): {
  construction: IConstruction | null;
  loading: boolean;
} => {
  const {id} = useParams();
  const {data: construction} = useSelector(
    (state: RootState) => state.construction,
  );
  const {messages} = useSelector((state: RootState) => state.signalRMessages);
  const {all} = useSelector((state: RootState) => state.constructions);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const {
    getConstructionById,
    getAllConstructions,
    getDefaultConstructionCalculationParameters,
  } = useConstructionHook();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      if (id !== undefined) {
        setLoading(true);
        const result = await getConstructionById(id);
        const defaultCalculationParameters =
          await getDefaultConstructionCalculationParameters();
        if (all.length <= 0) {
          const constructions = await getAllConstructions();
          dispatch(
            loadConstructions(getOrderByCreatedDate(constructions?.data ?? [])),
          );
        }
        dispatch(
          load({
            currentConstruction: result?.data,
            defaultCalculationParameters: defaultCalculationParameters?.data,
            all,
          }),
        );
        setLoading(false);
      }
    })();
  }, [id, all, messages]);

  return {
    construction,
    loading,
  };
};

export default useConstructionPageHook;
