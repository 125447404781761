import React from "react";
import {useDispatch} from "react-redux";
import {v4 as uuidv4} from "uuid";

import styles from "./CalculationsPanelListItem.module.scss";
import type CalculationsPanelListItemProps from "./CalculationsPanelListItemProps";
import CalculationStatus from "../../../../types/CalculationStatus";
import IsaacCalculationBadges from "../../../../components/isaacCalculationBadges/IsaacCalculationBadges";
import useCalculationHook from "../../../../hooks/useCalculationHook";
import useIssacToastHook from "../../../../hooks/useIssacToastHook";

import {updateStatus, removeById} from "../../constructionCalculationsSlice";
import IsaacToastWrapperType from "../../../../types/IsaacToastWrapperType";
import CalculationsPanelProfileCalculationItem from "../sidePanelProfileCalculationItem/CalculationsPanelProfileCalculationItem";

const CalculationsPanelListItem: React.FunctionComponent<
  CalculationsPanelListItemProps
> = ({calculation, isAllowToTakeAction}) => {
  const dispatch = useDispatch();

  const profileCalculation = calculation.profileCalculations[0]; // Note: this should not exist and should be merged with calculation

  const {withToast} = useIssacToastHook();
  const {stopCalculationById, deleteCalculationById} = useCalculationHook();

  const handleCalculationStop = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      await stopCalculationById(calculation.calculationId);
      dispatch(
        updateStatus({
          calculationId: calculation.calculationId,
          status: CalculationStatus.Interrupted,
        }),
      );
    })();
  };

  // Note: optimistic update is incomplete.
  const handleCalculationDelete = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      dispatch(removeById(calculation.calculationId));
      await withToast(
        async () =>
          await deleteCalculationById(calculation.calculationId, uuidv4()),
        IsaacToastWrapperType.OnlyError,
      );
    })();
  };

  return (
    <div key={calculation.calculationId} className={`${styles.calculationBox}`}>
      <IsaacCalculationBadges
        isAllowToTakeAction={isAllowToTakeAction}
        isSelected={calculation.selected}
        status={CalculationStatus[calculation.status as CalculationStatus]}
        handleCalculationStop={handleCalculationStop}
        handleCalculationDelete={handleCalculationDelete}
      />

      <div
        className={`${styles.calculationProfile} ${styles.calculationProfileOpened}`}>
        <CalculationsPanelProfileCalculationItem
          key={0}
          calculation={calculation}
          profileCalculation={profileCalculation}
        />
      </div>
    </div>
  );
};

CalculationsPanelListItem.displayName = "CalculationsPanelListItem";

export default CalculationsPanelListItem;
