import React from "react";
import {PrimaryButton, type ICommandBarItemProps} from "@fluentui/react";

export const getDefaultCommandBarItems = (
  showSaveModifiedBtn: boolean | undefined,
  handleOpenClosePanel: () => void,
  handleSaveModifiedConstruction: () => Promise<void>,
): any => {
  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: "calculations",
      onRender: () => (
        <PrimaryButton
          aria-label="View all calculations"
          text="View all calculations"
          iconProps={{iconName: "SidePanel"}}
          onClick={handleOpenClosePanel}
        />
      ),
    },
  ];

  if (showSaveModifiedBtn !== undefined && showSaveModifiedBtn) {
    commandBarItems.push({
      key: "saveModified",
      onRender: () => (
        <PrimaryButton
          aria-label="Save modified construction"
          text="Save modified construction"
          iconProps={{iconName: "SaveAs"}}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleSaveModifiedConstruction}
        />
      ),
    });
  }

  return commandBarItems;
};
