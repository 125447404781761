export const findPropertyByName = <T>(
  obj: object | any,
  propertyName: string,
): any | undefined => {
  if (Array.isArray(obj)) {
    for (const property of obj) {
      const result = findPropertyByName<T>(property, propertyName);
      if (result !== undefined) {
        return result;
      }
    }
  } else if (typeof obj === "object" && obj !== null) {
    if (propertyName in obj) {
      return obj[propertyName] as T;
    }
    for (const key in obj) {
      const result = findPropertyByName<T>(obj[key], propertyName);
      if (result !== undefined) {
        return result;
      }
    }
  }

  return undefined;
};

export const groupObjectsByProperty = <T>(
  objs: T[],
  propertyName: string,
): Map<string, T[]> => {
  const groupedObjects = new Map<string, T[]>();
  objs.forEach(obj => {
    const propertyValue = obj[propertyName as keyof typeof obj];
    const key = String(propertyValue);

    if (groupedObjects.has(key)) {
      groupedObjects.get(key)?.push(obj);
    } else {
      groupedObjects.set(key, [obj]);
    }
  });
  return groupedObjects;
};
