import React from "react";
import {Stack} from "@fluentui/react";

import styles from "./EditablePropertiesPanel.module.scss";
import IsaacList from "../../../../components/isaacList/IsaacList";
import type EditablePropertiesPanelProps from "./EditablePropertiesPanelProps";
import type RenderableEditableProperty from "../../../../types/RenderableEditableProperty";

const EditablePropertiesPanel: React.FunctionComponent<
  EditablePropertiesPanelProps
> = ({items}) => {
  return (
    <>
      {items?.length > 0 && (
        <Stack grow={1} className={styles.container}>
          {/* TODO: Move all text into translation files */}
          <h3 className={styles.header}>Editable properties</h3>
          <IsaacList<RenderableEditableProperty>
            items={items}
            onRenderCell={item => (
              <>
                {`${item?.entity} - ${item?.property} :`}{" "}
                <s>{item?.oldValue}</s> <b>{item?.currentValue}</b>
              </>
            )}
          />
        </Stack>
      )}
    </>
  );
};

export default EditablePropertiesPanel;
