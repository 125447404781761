import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {type SignalRMessage} from "../models/Messages";
import {type RootState} from "../../../store";
import {add} from "../../../pages/layout/messagesSlice";
import SignalRService from "../SignalRService";

const useSignalRMessages = (): SignalRMessage[] => {
  const dispatch = useDispatch();
  const [signalRServiceInstance, setSignalRServiceInstance] = useState<
    SignalRService | undefined
  >();

  const {messages} = useSelector((state: RootState) => state.signalRMessages);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      if (signalRServiceInstance === undefined) {
        const instance = await SignalRService.create();
        if (instance !== undefined) {
          setSignalRServiceInstance(instance);
        }
      }
    })();
  }, []);

  useEffect(() => {
    signalRServiceInstance?.events(message => {
      if (message !== undefined) {
        dispatch(add(message));
      }
    });
  }, [signalRServiceInstance?.events]);

  return messages;
};

export default useSignalRMessages;
