import React from "react";
import {Dialog, SpinnerSize, MessageBar, MessageBarType} from "@fluentui/react";
import type IsaacDialogProps from "./IsaacDialogProps";
import IsaacLoading from "../isaacLoading/IsaacLoading";

const IsaacDialog: React.FunctionComponent<IsaacDialogProps> = ({
  isHidden: hidden,
  isLoading,
  errorMessage,
  successMessage,
  dialogContentProps,
  modalProps,
  children,
  error,
  handleDialogDismiss,
}) => {
  return (
    <Dialog
      hidden={hidden}
      modalProps={modalProps}
      dialogContentProps={dialogContentProps}
      onDismiss={() => {
        handleDialogDismiss(prevState => !(prevState as boolean));
      }}>
      {isLoading === true && (
        <IsaacLoading loading={isLoading} size={SpinnerSize.large} />
      )}

      {isLoading === false && error !== null && error !== undefined && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          dismissButtonAriaLabel="Close">
          {errorMessage}
        </MessageBar>
      )}

      {isLoading === false && error === null && (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={true}
          dismissButtonAriaLabel="Close">
          {successMessage}
        </MessageBar>
      )}

      {children}
    </Dialog>
  );
};

export default IsaacDialog;
