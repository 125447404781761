import React from "react";
import {CommandBar} from "@fluentui/react";

import type IsaacCommandBarProps from "./IsaacCommandBarProps";

const IsaacCommandBar: React.FunctionComponent<IsaacCommandBarProps> = ({
  items,
  farItems,
}) => {
  return (
    <CommandBar
      items={items}
      farItems={farItems}
      style={{marginBottom: "15px"}}
    />
  );
};

export default IsaacCommandBar;
