/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment @typescript-eslint/prefer-nullish-coalescing
// @ts-nocheck

import {resolve} from "path";
import {EDITABLE} from "../../types/EditableItem";
export default class SrvCore {
  // --- SORT
  static sortItems = (items: any, sortBy: string, descending: boolean): any => {
    if (descending) {
      return items.sort((a: any, b: any) => {
        if (a[sortBy] < b[sortBy]) {
          return 1;
        }
        if (a[sortBy] > b[sortBy]) {
          return -1;
        }
        return 0;
      });
    } else {
      return items.sort((a: any, b: any) => {
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        return 0;
      });
    }
  };

  // --- FORMAT DATE FRIENDLY
  static formatDateFriendly(date: any) {
    var formatted = "";
    if (date) {
      var day = date.getDate();
      var month = date.getMonth();
      var year = date.getFullYear();
      var dayOfWeek = date.getDay();

      var monthNames = new Array(
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",
      );
      var dayNames = new Array(
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      );

      formatted =
        dayNames[dayOfWeek] +
        " " +
        day +
        " " +
        monthNames[month] +
        " " +
        year +
        "   ";
    }
    return formatted;
  }

  // --- FORMAT DATE FRIENDLY
  static formatDateFriendlyShort(date: any) {
    var formatted = "";
    if (date) {
      var day = date.getDate();
      var month = date.getMonth();
      var year = date.getFullYear();
      var dayOfWeek = date.getDay();

      var monthNames = new Array(
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      );

      formatted = day + " " + monthNames[month] + " " + year;
    }
    return formatted;
  }

  // --- FORMAT DATE FRIENDLY
  static formatMultiDateFriendlyShort(date1: any, date2: any) {
    var formatted = "";
    if (date1 && date2) {
      var day1 = date1.getDate();
      var day2 = date2.getDate();
      var month1 = date1.getMonth();
      var month2 = date2.getMonth();
      var year1 = date1.getFullYear();
      var year2 = date2.getFullYear();

      var monthNames = new Array(
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      );

      if (day1 !== day2) {
        if (month1 !== month2) {
          if (year1 !== year2) {
            formatted =
              day1 +
              " " +
              monthNames[month1] +
              " " +
              year1 +
              "-" +
              day2 +
              " " +
              monthNames[month2] +
              " " +
              year2;
          } else {
            formatted =
              day1 +
              " " +
              monthNames[month1] +
              "-" +
              day2 +
              " " +
              monthNames[month2] +
              " " +
              year1;
          }
        } else {
          formatted =
            day1 + "-" + day2 + " " + monthNames[month1] + " " + year1;
        }
      } else {
        formatted = day1 + " " + monthNames[month1] + " " + year1;
      }
    }
    return formatted;
  }

  // --- FORMAT TIME FRIENDLY
  static formatTimeFriendly(date: any) {
    var formatted = "";
    if (date) {
      var hour = date.toLocaleTimeString();
      formatted = hour;
    }
    return formatted;
  }

  // --- FORMAT DATE
  static formatDate(pdate: Date, format: string) {
    if (!format) format = "DD/MM/YYYY HH:MM";

    if (!isNaN(pdate)) pdate = +pdate;

    let d = new Date(pdate);
    let date = "";

    if (format == "DD/MM") {
      date += ("0" + d.getDate()).slice(-2) + "/";
      date += ("0" + (d.getMonth() + 1)).slice(-2);
    }

    if (format == "MM/YYYY") {
      date += ("0" + (d.getMonth() + 1)).slice(-2) + "/";
      date += d.getFullYear();
    }

    if (format == "DD/MM/YYYY") {
      date += ("0" + d.getDate()).slice(-2) + "/";
      date += ("0" + (d.getMonth() + 1)).slice(-2) + "/";
      date += d.getFullYear();
    }

    if (format == "MM-DD-YYYY") {
      date += ("0" + (d.getMonth() + 1)).slice(-2) + "-";
      date += ("0" + d.getDate()).slice(-2) + "-";
      date += d.getFullYear();
    }

    if (format == "DD-MM-YYYY") {
      date += ("0" + d.getDate()).slice(-2) + "-";
      date += ("0" + (d.getMonth() + 1)).slice(-2) + "-";
      date += d.getFullYear();
    }

    if (format == "YYYY-MM-DD") {
      date += d.getFullYear() + "-";
      date += ("0" + (d.getMonth() + 1)).slice(-2) + "-";
      date += ("0" + d.getDate()).slice(-2);
    }

    if (format == "YYYY-MM-DD-HH:MM") {
      date += d.getFullYear() + " ";
      date += ("0" + (d.getMonth() + 1)).slice(-2) + "-";
      date += ("0" + d.getDate()).slice(-2) + "-";
      date += ("0" + d.getHours()).slice(-2) + ":";
      date += ("0" + d.getMinutes()).slice(-2);
    }

    if (format == "DD/MM/YYYY HH:MM") {
      date += ("0" + d.getDate()).slice(-2) + "/";
      date += ("0" + (d.getMonth() + 1)).slice(-2) + "/";
      date += d.getFullYear() + " ";
      date += ("0" + d.getHours()).slice(-2) + ":";
      date += ("0" + d.getMinutes()).slice(-2);
    }

    if (format == "DD/MM/YYYY HH:MM local") {
      date += ("0" + d.getDate()).slice(-2) + "/";
      date += ("0" + (d.getMonth() + 1)).slice(-2) + "/";
      date += d.getFullYear() + " ";
      date += d.toLocaleTimeString();
      //date += ('0'+d.getHours()).slice(-2)+':';
      //date += ('0'+d.getMinutes()).slice(-2);
    }

    if (format == "HH:MM") {
      date += ("0" + d.getHours()).slice(-2) + ":";
      date += ("0" + d.getMinutes()).slice(-2);
    }

    if (format == "YYYY,MM,DD") {
      date += d.getFullYear();
      date += ("0" + (d.getMonth() + 1)).slice(-2) + ",";
      date += ("0" + d.getDate()).slice(-2) + ",";
    }

    return date;
  }

  // --- TIME AGO
  static MONTH_NAMES = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  static getFormattedDate(
    date: any,
    prefomattedDate = false,
    hideYear = false,
  ) {
    const day = date.getDate();
    const month = this.MONTH_NAMES[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    let minutes = date.getMinutes();

    if (minutes < 10) {
      // Adding leading zero to minutes
      minutes = `0${minutes}`;
    }

    if (prefomattedDate) {
      // Today at 10:20
      // Yesterday at 10:20
      return `${prefomattedDate} at ${hours}:${minutes}`;
    }

    if (hideYear) {
      // 10. January at 10:20
      return `${day} ${month} at ${hours}:${minutes}`;
    }

    // 10. January 2017. at 10:20
    return `${day} ${month} ${year} at ${hours}:${minutes}`;
  }

  // --- Main function
  static timeAgo(dateParam: any) {
    if (!dateParam) {
      return null;
    }

    const date =
      typeof dateParam === "object" ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(Date.now() - DAY_IN_MS);
    const seconds = Math.round((Date.now() - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();

    if (seconds < 5) {
      return "now";
    } else if (seconds < 60) {
      return `${seconds} seconds ago`;
    } else if (seconds < 90) {
      return "about a minute ago";
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (isToday) {
      return this.getFormattedDate(date); // Today at 10:20
    } else if (isYesterday) {
      return this.getFormattedDate(date); // Yesterday at 10:20
    } else if (isThisYear) {
      return this.getFormattedDate(date, false, true); // 10. January at 10:20
    }

    return this.getFormattedDate(date); // 10. January 2017. at 10:20
  }

  // --- TRUNC TEXT
  static truncateText(text: any, nbr: any) {
    var newText =
      text && text !== ""
        ? text.trim(text).substring(0, nbr).split(" ").slice(0, -1).join(" ") +
          "..."
        : "";

    return newText;
  }

  // --- GET VALUE BY KEY
  static getValueByKey(data: any, key: any) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].Key == key) {
        return data[i].Value;
      }
    }

    return false;
  }

  // --- OBJECT GROUP BY
  // static groupObjectBy(data: any, key: any): any {
  // 	let groups = { };

  // 	data.forEach(function(item: any){
  // 		let list:Array<any> = groups[item[key]];

  // 		if(list){
  // 			list.push(item);
  // 		} else{
  // 			groups[item[key]] = [item];
  // 		}
  // 	});

  // 	return groups;
  // }

  // --- Format Bytes
  static formatBytes(bytes: any) {
    if (bytes < 1024) return Math.ceil(bytes) + " Bytes";
    else if (bytes < 1048576) return Math.ceil(bytes / 1024) + " KB";
    else if (bytes < 1073741824) return Math.ceil(bytes / 1048576) + " MB";
    else return Math.ceil(bytes / 1073741824) + " GB";
  }

  // --- Create Date array
  static createDateArray(start: any, end: any) {
    var datearray = [];

    while (start <= end) {
      datearray.push(new Date(start));
      start.setDate(start.getDate() + 1);
    }

    return datearray;
  }

  // --- Image to dataURL
  static toDataURL(url: string, callback: any) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  // --- Parse URL properties
  static parse(input?: any, options?: any) {
    options = Object.assign({decode: true, arrayFormat: "none"}, options);

    const formatter = this.parserForArrayFormat(options);

    // Create an object with no prototype
    const ret = Object.create(null);

    if (typeof input !== "string") {
      return ret;
    }

    input = input.trim().replace(/^[?#&]/, "");

    if (!input) {
      return ret;
    }

    for (const param of input.split("&")) {
      let [key, value] = param.replace(/\+/g, " ").split("=");

      // Missing `=` should be `null`:
      // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
      value = value === undefined ? null : this.decode(value, options);

      formatter(this.decode(key, options), value, ret);
    }

    return Object.keys(ret)
      .sort()
      .reduce((result, key) => {
        const value = ret[key];
        if (
          Boolean(value) &&
          typeof value === "object" &&
          !Array.isArray(value)
        ) {
          // Sort object keys, not values
          result[key] = this.keysSorter(value);
        } else {
          result[key] = value;
        }

        return result;
      }, Object.create(null));
  }

  static parserForArrayFormat(options: any): any {
    let result;

    switch (options.arrayFormat) {
      case "index":
        return (key: any, value: any, accumulator: any) => {
          result = /\[(\d*)\]$/.exec(key);

          key = key.replace(/\[\d*\]$/, "");

          if (!result) {
            accumulator[key] = value;
            return;
          }

          if (accumulator[key] === undefined) {
            accumulator[key] = {};
          }

          accumulator[key][result[1]] = value;
        };
      case "bracket":
        return (key: any, value: any, accumulator: any) => {
          result = /(\[\])$/.exec(key);
          key = key.replace(/\[\]$/, "");

          if (!result) {
            accumulator[key] = value;
            return;
          }

          if (accumulator[key] === undefined) {
            accumulator[key] = [value];
            return;
          }

          accumulator[key] = [].concat(accumulator[key], value);
        };
      default:
        return (key: any, value: any, accumulator: any) => {
          if (accumulator[key] === undefined) {
            accumulator[key] = value;
            return;
          }

          accumulator[key] = [].concat(accumulator[key], value);
        };
    }
  }

  static decode(value: any, options: any) {
    if (options.decode) {
      return this.decodeComponent(value);
    }

    return value;
  }

  static decodeComponent(input: any) {
    var token = "%[a-f0-9]{2}";
    var singleMatcher = new RegExp(token, "gi");

    try {
      return decodeURIComponent(input);
    } catch (err) {
      var tokens = input.match(singleMatcher);

      for (var i = 1; i < tokens.length; i++) {
        input = this.decodeComponents(tokens, i).join("");

        tokens = input.match(singleMatcher);
      }

      return input;
    }
  }

  static decodeComponents(components: any, split?: any): any {
    try {
      // Try to decode the entire string first
      return decodeURIComponent(components.join(""));
    } catch (err) {
      // Do nothing
    }

    if (components.length === 1) {
      return components;
    }

    split = split ?? 1;

    // Split the array in 2 parts
    var left = components.slice(0, split);
    var right = components.slice(split);

    return Array.prototype.concat.call(
      [],
      this.decodeComponents(left),
      this.decodeComponents(right),
    );
  }

  static keysSorter(input: any): any {
    if (Array.isArray(input)) {
      return input.sort();
    }

    if (typeof input === "object") {
      return this.keysSorter(Object.keys(input))
        .sort((a: any, b: any) => Number(a) - Number(b))
        .map((key: any) => input[key]);
    }

    return input;
  }

  static getInitials(input: string) {
    var names = input.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  }

  static toFloat(num: any) {
    num = num.toString();

    let dotPos = num.indexOf(".");
    let commaPos = num.indexOf(",");

    if (dotPos < 0) dotPos = 0;

    if (commaPos < 0) commaPos = 0;

    let sep: any = false;

    if (dotPos > commaPos && dotPos) sep = dotPos;
    else {
      if (commaPos > dotPos && commaPos) sep = commaPos;
      else sep = false;
    }

    if (sep == false) return parseFloat(num.replace(/[^\d]/g, ""));

    return parseFloat(
      num.substr(0, sep).replace(/[^\d]/g, "") +
        "." +
        num.substr(sep + 1, num.length).replace(/[^0-9]/, ""),
    );
  }

  static getUrlParams() {
    let vars: Array<any> = [],
      hash: any;
    let hashes = window.location.href
      .slice(window.location.href.indexOf("?") + 1)
      .split("&");

    for (let i = 0; i < hashes.length; i++) {
      hash = hashes[i].split("=");
      vars.push(hash[0]);
      vars[hash[0]] = decodeURIComponent(hash[1]);
    }
    return vars;
  }

  static insertUrlParam(key: string, value: string) {
    key = encodeURI(key);
    value = encodeURI(value);

    let kvp = document.location.search.substr(1).split("&");
    if (!kvp) {
      document.location.search = "?" + key + "=" + value;
    } else {
      let i = kvp.length;
      var x;
      while (i--) {
        x = kvp[i].split("=");

        if (x[0] == key) {
          x[1] = value;
          kvp[i] = x.join("=");
          break;
        }
      }

      if (i < 0) {
        kvp[kvp.length] = [key, value].join("=");
      }

      //this will reload the page, it's likely better to store this until finished
      document.location.search = kvp.join("&");
    }
  }

  static removeDuplicatesFromObjectArray(array: any[], key: string) {
    return array.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[key]).indexOf(obj[key]) === pos;
    });
  }

  static GetPermMaskH(b: any) {
    let a = b.length;
    return a <= 10 ? 0 : parseInt(b.substring(2, a - 8), 16);
  }

  static GetPermMaskL(b: any) {
    let a = b.length;
    return a <= 10 ? parseInt(b) : parseInt(b.substring(a - 8, a), 16);
  }

  static updateQueryStringParam(key: any, value: any) {
    let baseUrl = [
        location.protocol,
        "//",
        location.host,
        location.pathname,
      ].join(""),
      urlQueryString = document.location.search,
      newParam = key + "=" + value,
      params = "?" + newParam;

    // If the "search" string exists, then build params from it
    if (urlQueryString) {
      let updateRegex = new RegExp("([?&])" + key + "[^&]*");
      let removeRegex = new RegExp("([?&])" + key + "=[^&;]+[&;]?");

      if (typeof value == "undefined" || value == null || value == "") {
        // Remove param if value is empty
        params = urlQueryString.replace(removeRegex, "$1");
        params = params.replace(/[&;]$/, "");
      } else if (urlQueryString.match(updateRegex) !== null) {
        // If param exists already, update it
        params = urlQueryString.replace(updateRegex, "$1" + newParam);
      } else {
        // Otherwise, add it to end of query string
        params = urlQueryString + "&" + newParam;
      }
    }

    // no parameter was set so we don't need the question mark
    params = params == "?" ? "" : params;

    window.history.replaceState({}, "", baseUrl + params);
  }

  static GetLcidList(): any {
    const lcidList = [
      {
        text: "English",
        key: "1033",
      },
      {
        text: "French",
        key: "1036",
      },
      {
        text: "Dutch",
        key: "1043",
      },
    ];

    return lcidList;
  }

  static GetRoleDefinitionId(role: any): number {
    let roles = [
      {
        text: "Full Control",
        key: 1073741829,
      },
      {
        text: "Contribute",
        key: 1073741827,
      },
      {
        text: "Edit",
        key: 1073741830,
      },
      {
        text: "Read",
        key: 1073741826,
      },
      {
        text: "View Only",
        key: 1073741924,
      },
    ];

    let roleId: number = -1;

    for (let i = 0; i < roles.length; i++) {
      if (role === roles[i].text) {
        roleId = roles[i].key;
        break;
      }
    }

    return roleId;
  }

  static HTMLEncode(str: any) {
    var i = str.length,
      aRet = [];

    while (i--) {
      var iC = str[i].charCodeAt();
      if (iC < 65 || iC > 127 || (iC > 90 && iC < 97)) {
        aRet[i] = "&#" + iC + ";";
      } else {
        aRet[i] = str[i];
      }
    }
    return aRet.join("");
  }

  static parseJwt(token: any) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );

    return JSON.parse(jsonPayload);
  }

  static chunckArray(items: any, chunk: any) {
    var index = 0;
    var arrayLength = items.length;
    var tempArray = [];

    for (index = 0; index < arrayLength; index += chunk) {
      let myChunk = items.slice(index, index + chunk);
      // Do something if you want with the group
      tempArray.push(myChunk);
    }

    return tempArray;
  }

  public static getConstructionData = (data: any): any => {
    const constructionData = [];
    for (const component in data) {
      //component: mast, beam, etc (the ones that are parents/sections)
      if (
        component !== "tags" &&
        component !== "name" &&
        component !== "lastUpdatedBy" &&
        component !== "phase" &&
        component !== "location" &&
        component !== "createdBy" &&
        component != "calculationParameters" &&
        data[component] &&
        typeof data[component] !== "number" &&
        typeof data[component] !== "string" &&
        typeof data[component] !== "boolean"
      ) {
        const section = {
          id: component,
          parent: null,
          name: component,
          count: data[component].length.toString(),
          columns:
            data[component].length > 0
              ? Object.keys(data[component][0]).filter(
                  i =>
                    i !== "family" &&
                    (data[component][0][i] == null ||
                      (typeof data[component][0][i] !== "object" &&
                        !Array.isArray(data[component][0][i]))),
                )
              : [],
          items: data[component],
        };
        //sections
        constructionData.push(section);

        const subsection =
          data[component].length > 0
            ? Object.keys(data[component][0]).filter(
                column =>
                  column.toLocaleLowerCase() !== "tooltips" &&
                  data[component][0][column] != null &&
                  column !== EDITABLE &&
                  (typeof data[component][0][column] === "object" ||
                    Array.isArray(data[component][0][column])),
              )
            : [];

        if (subsection.length > 0) {
          //subsections
          constructionData.push(
            ...subsection.map(subcomponent => {
              //component: mastFeatures, mastCoodinates, etc (the ones that are childrens/subSections)
              let columns = [];
              const subComponents = [].concat(
                ...data[component].map(c => c[subcomponent]),
              );
              if (
                Array.isArray(data[component][0][subcomponent]) &&
                data[component][0][subcomponent].length > 0
              ) {
                columns = Object.keys(
                  data[component].filter(x => x[subcomponent].length > 0)[0][
                    subcomponent
                  ][0],
                );
              } else if (typeof data[component][0][subcomponent] === "object") {
                columns = Object.keys(data[component][0][subcomponent]);
              }

              return {
                id: subcomponent,
                parent: component,
                name: subcomponent,
                count: subComponents.length,
                columns: columns.filter(s => s != EDITABLE),
                items: subComponents,
              };
            }),
          );
        }
      }
    }

    return constructionData;
  };

  public static getConstructionGeneralData = (construction: any): any => {
    let result = [];

    const locationData = {
      id: "location",
      columns: ["locationType", "locationCode", "phase"],
      items: [
        {
          locationType: construction.locationType,
          locationCode: construction.locationCode,
          phase: construction.phase,
        },
      ],
      name: "general",
    };

    const createdByData = {
      id: "createdBy",
      columns: ["createdBy", "createdOn", "modifiedBy", "modifiedOn"],
      items: [
        {
          createdBy: construction?.createdBy?.name,
          createdOn: this.formatDate(
            new Date(construction.createdOn),
            "DD/MM/YYYY",
          ),
          modifiedBy: construction?.lastUpdatedBy?.name,
          modifiedOn: this.formatDate(
            new Date(construction.lastUpdatedOn),
            "DD/MM/YYYY",
          ),
        },
      ],
    };

    const detailsData = {
      id: "detailsData",
      columns: [
        "referenceHeight",
        "previousGeneralSpan",
        "nextGeneralSpan",
        "defaultContactWireHeight",
        "defaultSystemHeight",
        "catenaryVoltage",
        "defaultVirtualSpan",
        "permittedDeformation",
        "permittedDeviation",
        "planId",
        "planPageNumber",
      ],
      items: [
        {
          referenceHeight: construction.referenceHeight,
          previousGeneralSpan: construction.previousGeneralSpan,
          nextGeneralSpan: construction.nextGeneralSpan,
          defaultContactWireHeight: construction.defaultContactWireHeight,
          defaultSystemHeight: construction.defaultSystemHeight,
          catenaryVoltage: construction.catenaryVoltage,
          defaultVirtualSpan: construction.defaultVirtualSpan,
          permittedDeformation: construction.permittedDeformation,
          permittedDeviation: construction.permittedDeviation,
          planId: construction.planId,
          planPageNumber: construction.planPageNumber,
        },
      ],
    };

    result.push(locationData);
    result.push(createdByData);
    result.push(detailsData);

    return result;
  };

  public static getConstructionCalculationParametersData = (
    construction: any,
  ): any => {
    let result = [];

    const calculationParametersData = {
      id: "calculationParameters",
      columns: [
        "iterationStartProfile",
        "iterationEndProfile",
        "hasIdenticalMastProfiles",
        "maxAllowedDeformationFactorBeamShape",
        "maxAllowedDeformationMastAtBeam",
        "maxAllowedDeformationFactorMastAtTop",
        "maxAllowedDeformationFactorMastShape",
        "maxAllowedDeformationMastTorsion",
        "overdimensioningFactor",
      ],
      items: [
        {
          ...construction.calculationParameters,
        },
      ],
      name: "calculationParameters",
    };

    result.push(calculationParametersData);
    return result;
  };

  public static convertToFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  public static async sleep(seconds: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, seconds * 1000));
  }
}
