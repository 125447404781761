import {type FormEvent, useState, useEffect} from "react";
import {type IComboBox, type IComboBoxOption} from "@fluentui/react";
import {useDispatch} from "react-redux";

import IsaacTagDialogType from "../types/IsaacTagDialogType";
import useTagHook from "../../../../../hooks/useTagHook";
import {addTags, removeTags} from "../../../constructionsSlice";

const useAddRemoveTagDialogHook = (
  tags: IComboBoxOption[],
  constructions: any[],
  type: string | undefined,
  isHidden: boolean,
  handleDialogDismiss: React.Dispatch<React.SetStateAction<boolean>>,
): {
  data: any;
  error: any;
  isLoading: boolean;
  selectedTags: string[] | undefined;
  handleChange: (
    event: FormEvent<IComboBox>,
    option?: IComboBoxOption | undefined,
    index?: number | undefined,
    value?: string | undefined,
  ) => void;
  handleAddDelete: () => Promise<void>;
} => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [currentTags, setCurrentTags] = useState<IComboBoxOption[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const {deleteTagsFromConstructions, addTagsToConstructions} = useTagHook();

  useEffect(() => {
    if (!isHidden) {
      setError(undefined);
      if (type === IsaacTagDialogType.ADD) {
        setCurrentTags(tags);
      } else {
        if (constructions !== undefined) {
          const constructionTags: IComboBoxOption[] = [];
          constructions
            .filter(c => c.isSelected)
            .forEach(c =>
              c.tags?.forEach((tag: string) => {
                if (constructionTags.some(ct => ct.key === tag)) {
                  return;
                }
                constructionTags.push({key: tag, text: tag});
              }),
            );
          setCurrentTags(constructionTags);
        }
      }
      setIsLoading(false);
    }
  }, [isHidden, constructions]);

  const handleChange = (
    event: FormEvent<IComboBox>,
    option?: IComboBoxOption | undefined,
    index?: number | undefined,
    value?: string | undefined,
  ): void => {
    if (option !== undefined) {
      setSelectedTags(prevState => {
        return option.selected === true
          ? [...prevState, option.text]
          : [...prevState?.filter(o => o !== option.text)];
      });

      return;
    }
    if (value !== undefined) {
      setCurrentTags((preState: IComboBoxOption[]) => [
        ...preState,
        {key: value, text: value, selected: true},
      ]);
      setSelectedTags(prevState => [...prevState, value]);
    }
  };

  // Note: refactor this to simplify logic and the way the error is set. Add/Remove logic is very similar
  const handleAddDelete = async (): Promise<void> => {
    setIsLoading(true);
    const tgs: IComboBoxOption[] = selectedTags.map(t => ({
      key: t,
      text: t,
    }));
    if (type === IsaacTagDialogType.ADD) {
      const result = await addTagsToConstructions(
        constructions.filter(c => c.isSelected).map(c => c.id as string),
        selectedTags ?? [],
      );
      if (!result.some(r => !r.success)) {
        dispatch(
          addTags({
            tags: tgs,
            constructionId: constructions.filter(c => c.isSelected)[0]?.id,
          }),
        );
        setSelectedTags([]);
        handleDialogDismiss(true);
      } else {
        setError(
          result.some(r => !r.success) ? "Failed to add tag/s." : undefined,
        );
      }
    } else {
      const result = await deleteTagsFromConstructions(
        constructions.filter(c => c.isSelected).map(c => c.id as string),
        selectedTags ?? [],
      );
      if (!result.some(r => !r.success)) {
        dispatch(
          removeTags({
            tags: tgs,
            constructionId: constructions.filter(c => c.isSelected)[0]?.id,
          }),
        );
        setSelectedTags([]);
        handleDialogDismiss(true);
      } else {
        setError(
          result.some(r => !r.success) ? "Failed to remove tag/s." : undefined,
        );
      }
    }

    setIsLoading(false);
  };

  return {
    data: currentTags,
    isLoading,
    error,
    selectedTags,
    handleChange,
    handleAddDelete,
  };
};

export default useAddRemoveTagDialogHook;
