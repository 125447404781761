import React from "react";
import {IconButton, TooltipHost} from "@fluentui/react";

import type IsaacEntityNavigationProps from "./IsaacEntityNavigationProps";
import {useNavigate} from "react-router-dom";

const IsaacEntityNavigation: React.FunctionComponent<
  IsaacEntityNavigationProps
> = ({nextPath, prevPath, nextTooTip, prevTooTip}) => {
  const navigate = useNavigate();
  return (
    <>
      {prevPath !== null && (
        <TooltipHost content={prevTooTip ?? "Go to previous."}>
          <IconButton
            iconProps={{iconName: "ChevronLeftMed"}}
            onClick={() => {
              navigate(prevPath);
            }}
          />
        </TooltipHost>
      )}
      {nextPath !== null && (
        <TooltipHost content={nextTooTip ?? "Go to previous."}>
          <IconButton
            iconProps={{iconName: "ChevronRightMed"}}
            onClick={() => {
              navigate(nextPath);
            }}
          />
        </TooltipHost>
      )}
    </>
  );
};

export default IsaacEntityNavigation;
