import React from "react";
import styles from "../../ConstructionCalculations.module.scss";
import {CommandBar} from "@fluentui/react";
import {getDefaultCommandBarItems} from "./CommandBarItems";

const CalculationCommandBar: React.FunctionComponent<{
  hasCalculations: boolean;
  showSaveModifiedBtn: boolean | undefined;
  setCalculationPanelState: () => void;
  handleSaveModifiedConstruction: () => Promise<void>;
}> = ({
  hasCalculations,
  showSaveModifiedBtn,
  handleSaveModifiedConstruction,
  setCalculationPanelState,
}) => {
  const handleOpenClosePanel = (): void => {
    setCalculationPanelState();
  };

  const items = getDefaultCommandBarItems(
    showSaveModifiedBtn,
    handleOpenClosePanel,
    handleSaveModifiedConstruction,
  );

  return (
    <>
      {hasCalculations && (
        <div className={styles.commandBar}>
          <CommandBar items={items} style={{marginBottom: "15px"}} />
        </div>
      )}
    </>
  );
};

export default CalculationCommandBar;
