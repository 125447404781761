import React from "react";
import styles from "./IsaacLoading.module.scss";
import {Spinner, SpinnerSize} from "@fluentui/react";

import type IsaacLoadingProps from "./IsaacLoadingProps";

const IsaacLoading: React.FunctionComponent<IsaacLoadingProps> = ({
  loading,
  size,
  message,
}) => (
  <>
    {loading && (
      <Spinner
        size={size ?? SpinnerSize.large}
        label={message ?? "Loading..."}
        className={styles.isaacLoading}
      />
    )}
  </>
);

export default IsaacLoading;
