import type ApiResponse from "../../api/types/ApiResponse";

export enum MessageType {
  CalculationCompleted = "CalculationCompleted",
  CalculationDeleted = "CalculationDeleted",
  ConstructionUnFrozen = "ConstructionUnFrozen",
  ConstructionFrozen = "ConstructionFrozen",
  ConstructionUpdated = "ConstructionUpdated",
  ProfileCalculationUpdated = "ProfileCalculationUpdated",
}
export interface SignalRMessage extends ApiResponse<any> {
  type: MessageType;
  timeStamp: Date;
  isRead: boolean;
}
export interface CalculationMessage {
  id: string;
  constructionId: string;
  status: string;
}

export interface ConstructionStatusChangeMessage extends SignalRMessage {
  id: string;
}
