import {createTheme, FontWeights} from "@fluentui/react";

const appLayoutTheme = createTheme({
  palette: {
    themePrimary: "#CFDDE3",
  },
  components: {
    PrimaryButton: {
      styles: {
        root: {
          color: "#262626",
        },
        icon: {
          color: "#262626",
        },
        iconHovered: {
          color: "#FFFFFF",
        },
        label: {
          fontWeight: FontWeights.regular,
        },
      },
    },
    Nav: {
      styles: {
        link: {color: "#0064EE"},
      },
    },
  },
});

export default appLayoutTheme;
