import React from "react";
import type ICalculationConstructionDetailsProps from "./ICalculationConstructionDetailsProps";
import styles from "./CalculationConstructionDetails.module.scss";

const CalculationConstructionDetails: React.FunctionComponent<
  ICalculationConstructionDetailsProps
> = ({input, selectedMastNumber, profileCalculationResults}) => {
  const selectedMast = input.masts?.find(x => x.number === selectedMastNumber);
  const mastProfileFromCalculation =
    profileCalculationResults?.mastResults?.find(
      (mr: any) => mr.mastNumber === selectedMastNumber,
    );
  return (
    <div className={styles.infoContainer}>
      <div className={styles.infoBox}>
        <div className={styles.valueBox}>
          <div>Minimal mast profile: </div>
          <div className={styles.valueYellow}>
            {input.minimalMastProfile ?? "-"}
          </div>
        </div>
      </div>
      <div className={styles.infoBox}>
        <div className={styles.valueBox}>
          <div>Mast profile: </div>
          <div className={styles.value}>
            {mastProfileFromCalculation?.profileType ?? "-"}
          </div>
        </div>
        <div className={styles.valueBox}>
          <div>Mast length: </div>
          <div className={styles.value}>{selectedMast?.length ?? "-"}</div>
        </div>
        <div className={styles.valueBox}>
          <div>Mast leveling: </div>
          <div className={styles.value}>{selectedMast?.leveling ?? "-"}</div>
        </div>
        <div className={styles.valueBox}>
          <div>Mast Reverse Slope: </div>
          <div className={styles.value}>
            {selectedMast?.reverseSlope ?? "-"}
          </div>
        </div>
      </div>
      <div className={styles.infoBox}>
        <div className={styles.valueBox}>
          <div>Minimal foundation profile: </div>
          <div className={styles.valueYellow}>
            {input.minimalFoundationType ?? "-"}
          </div>
        </div>
        <div className={styles.valueBox}>
          <div>Additional Depth of the Foundation: </div>
          <div className={styles.valueYellow}>
            {input.additionalDepthOfTheFoundation ?? "-"}
          </div>
        </div>
        <div className={styles.valueBox}>
          <div>Minimum length of mast in the foundation: </div>
          <div className={styles.valueYellow}>
            {input.minimumLengthOfMastInTheFoundation ?? "-"}
          </div>
        </div>
      </div>
      <div className={styles.infoBox}>
        <div className={styles.valueBox}>
          <div>Permitted deformation: </div>
          <div className={styles.value}>
            {input.permittedDeformation ?? "-"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculationConstructionDetails;
