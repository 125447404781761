import React from "react";
import {Pagination} from "@fluentui/react-experiments";

import type ConstructionPaginationProps from "./ConstructionPaginationProps";

const ConstructionPagination: React.FunctionComponent<
  ConstructionPaginationProps
> = ({pagination, handlePageChange}) => {
  return (
    <Pagination
      onPageChange={handlePageChange}
      selectedPageIndex={pagination.currentPage}
      pageCount={Math.ceil(pagination.total / pagination.pageSize)}
      format={"comboBox"}
      previousPageAriaLabel={"previous page"}
      nextPageAriaLabel={"next page"}
      pageAriaLabel={"page"}
      selectedAriaLabel={"selected"}
      previousPageIconProps={{iconName: "ChevronLeftSmall"}}
      nextPageIconProps={{iconName: "ChevronRightSmall"}}
      styles={{
        root: {display: "flex", justifyContent: "center", margin: "5px"},
      }}
    />
  );
};
export default ConstructionPagination;
