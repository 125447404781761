import {createSlice} from "@reduxjs/toolkit";

import type {PayloadAction} from "@reduxjs/toolkit";
import type IConstruction from "./types/IConstruction";
import type {EditableProperty} from "../../types/EditableConstruction";
import type {IDefaultConstructionCalculationParameters} from "../../types/IDefaultConstructionCalculationParameters";
import type SelectedEntity from "../../types/SelectedEntity";
import core from "../../utils/_legacy/SrvCore";

export interface ConstructionState {
  loading: boolean;
  data: IConstruction | null;
  editableProperties: EditableProperty[];
  deselectedEntities: SelectedEntity[];
  defaultCalculationParams: IDefaultConstructionCalculationParameters | null;
  nextId: string | null;
  prevId: string | null;
}

const initialState: ConstructionState = {
  loading: true,
  data: null,
  editableProperties: [],
  deselectedEntities: [],
  defaultCalculationParams: null,
  nextId: null,
  prevId: null,
};

export const constructionSlice = createSlice({
  name: "construction",
  initialState,
  reducers: {
    load: (
      state,
      {
        payload,
      }: PayloadAction<{
        currentConstruction: any;
        defaultCalculationParameters: IDefaultConstructionCalculationParameters | null;
        all: any[];
      } | null>,
    ) => {
      if (payload !== null) {
        state.data = payload.currentConstruction;
        state.editableProperties = [];
        state.deselectedEntities = [];
        state.defaultCalculationParams = payload.defaultCalculationParameters;
        const currentIndex = payload.all?.findIndex(
          c => c.id === payload.currentConstruction?.id,
        );

        if (currentIndex !== undefined) {
          state.prevId = payload.all[currentIndex - 1]?.id ?? null;
          state.nextId = payload.all[currentIndex + 1]?.id ?? null;
        }
      }
      state.loading = false;
    },
    removeTag: (state, {payload}: PayloadAction<string>) => {
      if (state.data !== null) {
        state.data.tags = state.data?.tags.filter(t => t !== payload);
      }
    },
    addTags: (state, {payload}: PayloadAction<string[]>) => {
      if (state.data !== null) {
        payload.forEach(tag => {
          const index = state.data?.tags.find(t => t === tag);
          if (index === undefined) {
            state.data?.tags.push(tag);
          }
        });
      }
    },
    updateEditableProps: (
      state,
      {payload}: PayloadAction<EditableProperty>,
    ) => {
      const eProp = state.editableProperties?.find(
        e =>
          e.entityId === payload?.entityId &&
          e.propertyName.toLocaleLowerCase() ===
            payload?.propertyName?.toLocaleLowerCase(),
      );
      const propertyAlreadyExists = eProp !== undefined;

      if (propertyAlreadyExists) {
        state.editableProperties = state.editableProperties?.filter(
          e =>
            e.entityId !== eProp.entityId &&
            e.propertyName.toLocaleLowerCase() ===
              payload?.propertyName?.toLocaleLowerCase(),
        );
      }

      state.editableProperties?.push({
        ...payload,
        propertyName: core.convertToFirstLetter(payload.propertyName),
        propertyValue: removeMirrorFromValue(payload.propertyValue),
      });

      if (isValueMirrored(payload.propertyValue)) {
        const mirroredEProp = {
          ...payload,
          property: "IsMirrored",
          value: "true",
        };
        state.editableProperties?.push(mirroredEProp);
      }
    },

    updateSelectedEntities: (
      state,
      {payload}: PayloadAction<SelectedEntity>,
    ) => {
      const entityIndex = state.deselectedEntities.findIndex(
        c =>
          c.entityId === payload.entityId &&
          c.entityType === payload.entityType,
      );

      if (entityIndex !== -1) {
        state.deselectedEntities = state.deselectedEntities.filter(
          c => c.entityId !== payload.entityId,
        );
        return state;
      }

      state.deselectedEntities.push(payload);
    },
    clear: state => {
      state.data = null;
    },
  },
});

const removeMirrorFromValue = (value: string | undefined): string => {
  if (value !== undefined) {
    return isValueMirrored(value)
      ? value?.substring(value.indexOf("-") + 1, value.length)
      : checkIfBoolean(value);
  }
  return "";
};

const checkIfBoolean = (value: string | boolean): string => {
  if (typeof value === "boolean") {
    return String(value);
  }
  return value;
};

const isValueMirrored = (value: string | undefined): boolean => {
  if (value !== undefined && typeof value === "string") {
    return value.includes("-");
  }
  return false;
};

export const {
  load,
  removeTag,
  addTags,
  clear,
  updateEditableProps,
  updateSelectedEntities,
} = constructionSlice.actions;

export default constructionSlice.reducer;
