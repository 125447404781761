import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import styles from "./Constructions.module.scss";
import {load, search, select} from "./constructionsSlice";
import {getOrderByCreatedDate} from "../../utils/DateFormatting";
import ConstructionsTable from "./components/table/ConstructionsTable";
import IsaacCommandBar from "../../components/isaacCommandBar/IsaacCommandBar";
import ConstructionsFilters from "./components/filters/ConstructionsFilters";
import AddRemoveTagDialog from "./components/tags/AddRemoveTagDialog";
import useConstructionsPageHook from "./hooks/useConstructionsPageHook";
import type {RootState} from "../../store";

const Constructions: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const {
    isTagDialogHidden,
    tagModalType,
    setTagDialogHidden,
    commandBarItems,
    getAllConstructions,
  } = useConstructionsPageHook();

  const {filters, page, loading, pagination} = useSelector(
    (state: RootState) => state.constructions,
  );

  const handleSelection = (selectedConstructions: any[]): void => {
    dispatch(select(selectedConstructions));
  };

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const response = await getAllConstructions();
      if (response?.data !== null) {
        dispatch(load(getOrderByCreatedDate(response.data)));
      } else {
        dispatch(load([]));
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadData();
  }, []);

  useEffect(() => {
    const areFilters = Object.values(filters).some(filter => {
      if (Array.isArray(filter)) {
        return filter.length > 0;
      } else {
        return filter !== undefined;
      }
    });

    if (areFilters && page?.length > 0) {
      dispatch(search(filters));
    }
  }, [filters]);

  return (
    <section className={`${styles.results} ms-motion-slideLeftIn`}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          <h2>Constructions</h2>
        </div>
        <div className={styles.commandBar}>
          <IsaacCommandBar items={commandBarItems} farItems={[]} />

          <AddRemoveTagDialog
            type={tagModalType}
            isHidden={isTagDialogHidden}
            handleDialogDismiss={setTagDialogHidden}
          />
        </div>

        <ConstructionsFilters />
        <ConstructionsTable
          loading={loading}
          page={page}
          pagination={pagination}
          handleSelection={handleSelection}
        />
      </div>
    </section>
  );
};

export default Constructions;
