export const toLowerCaseFirstLetterJsonReviver = (
  key: any,
  value: any,
): any => {
  if (typeof value === "object" && value !== null) {
    const newValue: any = {};
    for (const k in value) {
      newValue[k.charAt(0).toLowerCase() + k.slice(1)] = value[k];
    }
    return newValue;
  }
  return value;
};
