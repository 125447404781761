import type ApiResponse from "./types/ApiResponse";
import {toLowerCaseFirstLetterJsonReviver} from "../../utils/JsonUtils";
import ApiHttpVerbs from "./types/ApiHttpVerbs";

const fetchData = async <T>(
  token: string | undefined,
  url: string,
  method: string = ApiHttpVerbs.GET,
  customHeaders?: Record<string, string>,
  body?: any,
): Promise<ApiResponse<T>> => {
  const headers: Record<string, string> = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    ...customHeaders,
  };

  if (token === undefined) {
    throw new Error("You are not authenticated yet!!!");
  }

  const response = await fetch(url, {
    method,
    headers,
    body: body ?? undefined,
  });

  if (!response.ok) {
    const json = await response.text();
    const errorResponse: ApiResponse<T> = JSON.parse(
      json,
      toLowerCaseFirstLetterJsonReviver,
    );
    return errorResponse;
  }

  const parsedResponse: ApiResponse<T> =
    await handleSuccessWithEmptyBody<T>(response);

  return parsedResponse;
};

const handleSuccessWithEmptyBody = async <T>(
  response: Response,
): Promise<ApiResponse<T>> => {
  try {
    const contentDispositionHeader = response.headers.get(
      "Content-Disposition",
    );

    if (
      contentDispositionHeader !== null &&
      contentDispositionHeader !== undefined
    ) {
      const filenamePart = contentDispositionHeader
        .split(";")
        .find(n => n.includes("filename="));

      if (filenamePart !== undefined) {
        const parsed: ApiResponse<T> = {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          data: {
            blob: await response.blob(),
            fileName: filenamePart
              .replace("filename=", "")
              .replaceAll('"', "")
              .trim(),
          } as T,
          success: true,
        };

        return parsed;
      }
    }

    const json = await response.json();
    const parsed: ApiResponse<T> = {data: json, success: true};
    return parsed;
  } catch {
    const emptyResponse: ApiResponse<T> = {data: null as T, success: true};
    return await Promise.resolve(emptyResponse);
  }
};

export default fetchData;
