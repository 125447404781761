import React from "react";
import {toast} from "react-toastify";

import {
  MessageType,
  type SignalRMessage,
} from "../../../services/signalR/models/Messages";
import type MessageHandler from "../types/MessageHandler";
import IsaacToast from "../../isaacToast/IsaacToast";
import useCalculationCompleteHandler from "./useCalculationCompleteHandler";
import useDefaultHandler from "./useDefaultHandler";

const useMessageHandle = (): ((message: SignalRMessage) => void) => {
  const calculationCompleteHandler = useCalculationCompleteHandler();
  const defaultHandler = useDefaultHandler();

  const messageHandlers: MessageHandler = {
    [MessageType.CalculationCompleted]: calculationCompleteHandler,
    default: defaultHandler,
  };

  const handleMessage = (message: SignalRMessage): void => {
    if (!message.success) {
      // Note: exclude tsx code from hook.

      toast(
        <IsaacToast
          message={message.message ?? ""}
          toastType="error"
          additionalMessage="Server side error occurred!"
          correlationId={message.correlationId}
          traceId={message.traceId}
          statusCode={message.statusCode}
          extraInfo={
            new Map<string, string>([
              ["Construction ID", message.data.constructionId],
              ["Calculation ID", message.data.calculationId],
            ])
          }
        />,
        {
          toastId: message.data.constructionId,
          isLoading: false,
          autoClose: 3000,
          type: "error",
        },
      );
      return;
    }
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    (messageHandlers[message.type] || messageHandlers.default)(message);
  };

  return handleMessage;
};

export default useMessageHandle;
