import {configureStore} from "@reduxjs/toolkit";
import constructionsReducer from "../pages/constructions/constructionsSlice";
import constructionReduce from "../pages/construction/constructionSlice";
import constructionCalculationsReducer from "../pages/constructionCalculations/constructionCalculationsSlice";
import messagesReducer from "../pages/layout/messagesSlice";
import errorsReducer from "../pages/layout/errorsSlice";
import userInfoReducer from "../services/auth/userInfoSlice";

export const store = configureStore({
  reducer: {
    constructions: constructionsReducer,
    construction: constructionReduce,
    constructionCalculations: constructionCalculationsReducer,
    signalRMessages: messagesReducer,
    errors: errorsReducer,
    userInfo: userInfoReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
