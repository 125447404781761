import {useSelector, useDispatch} from "react-redux";

import type IFilters from "../../../../../types/IFilters";
import {
  formatDateToString,
  formatDateStringToString,
} from "../../../../../utils/DateFormatting";
import {search} from "../../../constructionsSlice";
import type {RootState} from "../../../../../store";

const useConstructionsFiltersHook = (): {
  filters: IFilters;
  handleFilterChange: (value: any | undefined, name: string) => void;
  handleFiltersClear: () => void;
  handleFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleFormattedDate: (date?: Date) => string;
} => {
  const {filters} = useSelector((state: RootState) => state.constructions);
  const dispatch = useDispatch();

  const handleFilterChange = (value: any | undefined, name: string): void => {
    if (typeof value === "object") {
      dispatch(
        search({
          ...filters,
          [name]:
            value.selected === true
              ? [...(filters as any)[name], value.key]
              : [
                  ...(filters as any)[name].filter(
                    (v: string) => v !== value.key,
                  ),
                ],
        }),
      );
    } else {
      dispatch(search({...filters, [name]: value}));
    }
  };

  const handleFiltersClear = (): void => {
    const emptyFilters: IFilters = {
      name: undefined,
      from: undefined,
      to: undefined,
      locationCodes: [],
      tags: [],
      createdBy: [],
    };
    dispatch(search(emptyFilters));
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    search({
      ...filters,
      from: formatDateStringToString(filters.from),
      to: formatDateStringToString(filters.to),
    });
  };

  const handleFormattedDate = (date?: Date): string => formatDateToString(date);

  return {
    filters,
    handleFilterChange,
    handleFiltersClear,
    handleFormSubmit,
    handleFormattedDate,
  };
};

export default useConstructionsFiltersHook;
