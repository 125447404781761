import React from "react";
import styles from "./ConstructionsFilters.module.scss";
import {SearchBox, DatePicker, DefaultButton, ComboBox} from "@fluentui/react";
import {useSelector} from "react-redux";

import {type RootState} from "../../../../store";

import useConstructionsFiltersHook from "./hooks/useConstructionsFiltersHook";

const ConstructionsFilters: React.FunctionComponent = () => {
  const {tags, locationCodes, createdBy} = useSelector(
    (state: RootState) => state.constructions,
  );

  const {
    filters,
    handleFilterChange,
    handleFiltersClear,
    handleFormSubmit,
    handleFormattedDate,
  } = useConstructionsFiltersHook();

  return (
    <form className={styles.form} onSubmit={handleFormSubmit}>
      <SearchBox
        name="name"
        className={styles.name}
        value={filters.name ?? ""}
        placeholder="Search by name"
        onClear={() => {
          handleFilterChange(undefined, "name");
        }}
        onChange={e => {
          if (e !== undefined) {
            handleFilterChange(e?.target.value, e?.target.name);
          }
        }}
      />
      <DatePicker
        placeholder="From"
        className={styles.from}
        value={filters.from !== undefined ? new Date(filters.from) : undefined}
        onSelectDate={date => {
          handleFilterChange(date?.toString(), "from");
        }}
        formatDate={handleFormattedDate}
      />
      <DatePicker
        placeholder="To"
        className={styles.to}
        value={filters.to !== undefined ? new Date(filters.to) : undefined}
        onSelectDate={date => {
          handleFilterChange(date?.toString(), "to");
        }}
        formatDate={handleFormattedDate}
      />

      {/* Note: TagPicker should have been used but there is an active FluentUI bug that makes in un-usable on the current version of React. */}
      <ComboBox
        placeholder="Select tags"
        className={styles.tags}
        multiSelect
        options={tags}
        selectedKey={filters.tags}
        autoComplete={"on"}
        allowFreeform={true}
        onChange={(_, options) => {
          handleFilterChange(options, "tags");
        }}
      />
      {/* Note: TagPicker should have been used but there is an active FluentUI bug that makes in un-usable on the current version of React. */}
      <ComboBox
        placeholder="Select location codes"
        className={styles.codes}
        multiSelect
        options={locationCodes}
        selectedKey={filters.locationCodes}
        autoComplete={"on"}
        allowFreeform={true}
        onChange={(_, options) => {
          handleFilterChange(options, "locationCodes");
        }}
      />
      {/* Note: TagPicker should have been used but there is an active FluentUI bug that makes in un-usable on the current version of React. */}
      <ComboBox
        placeholder="Select created by"
        className={styles.codes}
        multiSelect
        options={createdBy}
        selectedKey={filters.createdBy}
        autoComplete={"on"}
        allowFreeform={true}
        onChange={(_, options) => {
          handleFilterChange(options, "createdBy");
        }}
      />
      {/* <PrimaryButton text="Search" type="submit" className={styles.button} /> */}
      <DefaultButton
        text="Clear"
        type="button"
        className={styles.button}
        onClick={handleFiltersClear}
      />
    </form>
  );
};

export default ConstructionsFilters;
