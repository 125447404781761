interface EditableItemType {
  name: string;
  type: EditableType;
  id: string;
}

enum EditableType {
  Number = "number",
  Text = "text",
  Select = "select",
  CheckBox = "checkBox",
}

const EDITABLE = "editable";
export {type EditableItemType, EditableType, EDITABLE};
