import {useEffect, useState} from "react";
import type RenderableDeselectedConstructionElement from "../../../../../types/RenderableDeselectedConstructionElement";

const useDeselectedElementsHook = (
  deselectedConstructionElements: any[],
): {items: RenderableDeselectedConstructionElement[]} => {
  const [items, setItems] = useState<RenderableDeselectedConstructionElement[]>(
    [],
  );
  useEffect(() => {
    const items: RenderableDeselectedConstructionElement[] =
      deselectedConstructionElements.map(d => ({
        compartment: d.compartment,
        entityType: d.entityType,
        position: d.position,
      }));

    if (items.length > 0) {
      setItems(items);
    }
  }, [deselectedConstructionElements]);

  return {items};
};

export default useDeselectedElementsHook;
