import React from "react";
import {Stack, Text, PrimaryButton} from "@fluentui/react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {flush} from "../layout/errorsSlice";
import PathConstants from "../../routes/pathConstants";

import type {RootState} from "../../store";
import IsaacEmpty from "../../components/isaacEmpty/IsaacEmpty";

const ErrorPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {errors} = useSelector((state: RootState) => state.errors);

  if (errors === undefined) {
    navigate(PathConstants.Home);
  }

  return (
    <Stack
      verticalAlign="center"
      horizontalAlign="center"
      verticalFill
      styles={{
        root: {
          margin: "auto",
          textAlign: "center",
        },
      }}>
      <Text variant="xxLarge">Oops! Something went wrong.</Text>

      {errors === undefined && (
        <Text variant="large">API communication error ocurred.</Text>
      )}
      {errors?.map((e, i) => (
        <Text key={i} variant="large">
          {e.message ?? e.exception}
        </Text>
      ))}

      <IsaacEmpty />

      <PrimaryButton
        text="Retry"
        onClick={() => {
          dispatch(flush());
          navigate(location.state.source as string);
        }}
      />
    </Stack>
  );
};

export default ErrorPage;
