import React, {useEffect, useState} from "react";
import {
  ComboBox,
  Spinner,
  SpinnerSize,
  type IComboBox,
  type IComboBoxOption,
  type IComboBoxStyles,
} from "@fluentui/react";

import useEditableHook from "../../../hooks/useEditableHook";
import type ISelectedProps from "./ISelectProps";

const comboBoxStyles: Partial<IComboBoxStyles> = {
  root: {
    maxWidth: 100,
    maxHeight: 100,
    marginRight: 5,
  },
  callout: {width: "fit-content"},
};
const Select: React.FunctionComponent<ISelectedProps> = ({
  value,
  module,
  property,
  extraInfo,
  isDisabled,
  onValueChanged,
}) => {
  const [options, setOptions] = useState<IComboBoxOption[]>([]);
  const [allowFreeform, setAllowFreeform] = useState<boolean>(false);

  const [loading, IsLoading] = useState<boolean>();
  const {getTypeOptionsFromMatRef} = useEditableHook();

  const onCheckboxChanged = (
    _: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    __?: number,
    value?: string,
  ): void => {
    if (option !== undefined) {
      onValueChanged(option.text);
    } else {
      onValueChanged(value);
    }
  };

  const getOptions = async (): Promise<void> => {
    const filter: string = module === "devices" ? extraInfo.family : "";
    const optionsResult = await getTypeOptionsFromMatRef(
      module,
      property,
      filter,
    );
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (optionsResult) {
      setAllowFreeform(optionsResult.isFreeText as boolean);
      setOptions(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        optionsResult.options.map(
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          (opt: any) => ({key: opt, text: opt}) as IComboBoxOption,
        ),
      );
    }
    IsLoading(false);
  };

  useEffect(() => {
    void getOptions();
    IsLoading(true);
  }, []);

  return (
    <div style={{display: "flex"}}>
      <ComboBox
        selectedKey={value}
        options={options}
        styles={comboBoxStyles}
        allowFreeform={allowFreeform}
        autoComplete="on"
        onChange={onCheckboxChanged}
        disabled={isDisabled}
      />
      {loading === true && (
        <Spinner size={SpinnerSize.small} ariaLive="assertive" />
      )}
    </div>
  );
};

export default Select;
