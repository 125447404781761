import React, {useState} from "react";
import {CommandBar} from "@fluentui/react";

import styles from "../../Construction.module.scss";
import IsaacDeleteConstructionDialog from "../../../../components/isaacDeleteConstructionDialog/IsaacDeleteConstructionDialog";
import useConstructionCommandBar from "./useConstructionCommandBar";
import type ConstructionStatus from "../../../../types/ConstructionStatus";

const ConstructionCommandBar: React.FunctionComponent<{
  constructionStatus: ConstructionStatus | undefined;
  isAllowToTakeAction: boolean;
}> = ({isAllowToTakeAction, constructionStatus}) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(true);
  const {items} = useConstructionCommandBar(
    constructionStatus,
    isAllowToTakeAction,
    setIsDeleteDialogOpen,
  );

  return (
    <div className={styles.commandBar}>
      <CommandBar items={items} style={{marginBottom: "10px"}} />

      <IsaacDeleteConstructionDialog
        isHidden={isDeleteDialogOpen}
        handleDialogDismiss={setIsDeleteDialogOpen}
      />
    </div>
  );
};

export default ConstructionCommandBar;
