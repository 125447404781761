export const capitalizeFirstLetter = (value: string | undefined): string => {
  if (value === undefined) {
    return "";
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
};
export const toLowerCaseFirstLetter = (value: string | undefined): string => {
  if (value === undefined) {
    return "";
  }
  return value.charAt(0).toLocaleLowerCase() + value.slice(1);
};
