// Note: This should be removed
/* eslint-disable @typescript-eslint/no-misused-promises */

import React, {type FormEvent, useState, useEffect} from "react";
import {
  ComboBox,
  DefaultButton,
  DialogFooter,
  DialogType,
  type IComboBox,
  PrimaryButton,
  type IComboBoxOption,
} from "@fluentui/react";
import {useDispatch, useSelector} from "react-redux";

import {type RootState} from "../../../store";
import type AddTagsDialogProps from "./AddTagsDialogProps";
import type ApiResponse from "../../../services/api/types/ApiResponse";
import IsaacDialog from "../../../components/isaacDialog/IsaacDialog";
import useTagHook from "../../../hooks/useTagHook";
import {addTags} from "../../../pages/construction/constructionSlice";

const AddTagsDialog: React.FunctionComponent<AddTagsDialogProps> = ({
  isHidden,
  handleDismiss,
}) => {
  const {tags} = useSelector((state: RootState) => state.constructions);
  const {data} = useSelector((state: RootState) => state.construction);

  const [currentTags, setCurrentTags] = useState<IComboBoxOption[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [result, setResult] = useState<ApiResponse<any>>();
  const {addTagsToConstruction} = useTagHook();
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentTags(tags);
  }, []);

  const handleChange = (
    event: FormEvent<IComboBox>,
    option?: IComboBoxOption | undefined,
    index?: number | undefined,
    value?: string | undefined,
  ): void => {
    if (option !== undefined) {
      setSelectedTags(prevState => {
        return option.selected === true
          ? [...prevState, option.text]
          : [...prevState?.filter(o => o !== option.text)];
      });
      return;
    }
    if (value !== undefined) {
      setCurrentTags((preState: IComboBoxOption[]) => [
        ...preState,
        {key: value, text: value, selected: true},
      ]);
      setSelectedTags(prevState => [...prevState, value]);
    }
  };

  const handleAddTags = async (): Promise<void> => {
    setIsLoading(true);

    if (data !== null) {
      const response = await addTagsToConstruction(
        data.id,
        selectedTags.map(t => ({Value: t})),
      );
      setResult(response);
      dispatch(addTags(selectedTags));
    }
    setSelectedTags([]);
    handleDismiss();
    setIsLoading(false);
  };

  return (
    <IsaacDialog
      isHidden={isHidden}
      dialogContentProps={{
        type: DialogType.normal,
        title: "Add tag to the construction",
      }}
      modalProps={{
        titleAriaId: "tag",
        subtitleAriaId: "mySubTextId",
        isBlocking: true,
        containerClassName: "ms-dialogMainOverride ",
      }}
      isLoading={isLoading}
      errorMessage={result?.message}
      successMessage="Calculation created successfully"
      handleDialogDismiss={handleDismiss}>
      <>
        <ComboBox
          label="Add a new tag or select from existing tags."
          allowFreeform={true}
          multiSelect
          selectedKey={selectedTags}
          options={currentTags}
          onChange={handleChange}
        />
        <DialogFooter>
          <PrimaryButton
            onClick={handleAddTags}
            text="Add"
            disabled={selectedTags !== undefined && selectedTags.length <= 0}
          />
          <DefaultButton onClick={handleDismiss} text="Cancel" />
        </DialogFooter>
      </>
    </IsaacDialog>
  );
};

export default AddTagsDialog;
