import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import i18next from "../../i18n/config";
import {
  Dropdown,
  type IDropdownOption,
  type IDropdownStyles,
} from "@fluentui/react";

import Language from "../../types/Language";
import {changeLanguage} from "../../services/auth/userInfoSlice";
import type {RootState} from "../../store";

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: {width: 100},
};

const languageOptions: IDropdownOption[] = [
  {
    key: Language.English,
    text: "English",
  },
  {
    key: Language.ACAD,
    text: "ACAD Codes",
  },
];

const IsaacLanguageSwitcher: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const {language} = useSelector((state: RootState) => state.userInfo);

  useEffect(() => {
    void i18next.changeLanguage(language);
  }, [language]);

  const handleChange = (
    event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption<any> | undefined,
  ): void => {
    if (item !== undefined) {
      void i18next.changeLanguage(item.key.toString(), () => {
        dispatch(changeLanguage(item.key.toString()));
      });
    }
  };

  return (
    <Dropdown
      selectedKey={language}
      onChange={handleChange}
      placeholder="Language"
      options={languageOptions}
      styles={dropdownStyles}
    />
  );
};

export default IsaacLanguageSwitcher;
