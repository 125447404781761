import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {v4 as uuidv4} from "uuid";

import useConstructionHook from "../../../../hooks/useConstructionHook";
import useCalculationHook from "../../../../hooks/useCalculationHook";
import useIssacToastHook from "../../../../hooks/useIssacToastHook";
import useConstructionCommandBarItems from "./useConstructionCommandBarItems";
import IsaacToastWrapperType from "../../../../types/IsaacToastWrapperType";
import PathConstants from "../../../../routes/pathConstants";
import {addToLocaLStorage} from "../../../../utils/ManageLocalStorage";
import type {RootState} from "../../../../store";
import type {ICommandBarItemProps} from "@fluentui/react";
import type ConstructionStatus from "../../../../types/ConstructionStatus";
import type {EditableAndDeselectedElementsList} from "../../../../types/EditableAndDeselectedElementsList";

const useConstructionCommandBar = (
  constructionStatus: ConstructionStatus | undefined,
  isAllowToTakeAction: boolean,
  setIsDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
): {items: ICommandBarItemProps[]} => {
  const {id} = useParams();

  const {validateEditConstructionGeometrically, freezeConstructionById} =
    useConstructionHook();
  const {createStartCalculation} = useCalculationHook();

  const {withToast} = useIssacToastHook();
  const navigate = useNavigate();
  const {editableProperties} = useSelector(
    (state: RootState) => state.construction,
  );
  const {deselectedEntities} = useSelector(
    (state: RootState) => state.construction,
  );

  const newCalculation = async (
    id: string,
    correlationId: string,
  ): Promise<void> => {
    const collection: EditableAndDeselectedElementsList = {
      editableProperties: editableProperties ?? [],
      deselectedElements: deselectedEntities ?? [],
    };

    if (editableProperties !== undefined || deselectedEntities !== undefined) {
      const validationResult = await withToast<boolean>(
        async () => await validateEditConstructionGeometrically(id, collection),
        IsaacToastWrapperType.Full,
        undefined,
        `Validating editable construction geometrical...`,
        `Editable construction geometry is valid.`,
        `Failed to validate editable construction geometry!`,
      );

      if (!validationResult.success || validationResult?.data !== true) {
        return;
      }
    }
    addToLocaLStorage(`${id}.inProgress`, true);
    await withToast(
      async () => await createStartCalculation(id, correlationId, collection),
      IsaacToastWrapperType.Full,
      undefined,
      `Triggering new calculation...`,
      `Calculation triggered. You will receive a notification on completion.`,
      `Failed to trigger calculation!`,
    );
  };

  const handleNewCalculationTrigger = async (): Promise<void> => {
    await newCalculation(id ?? "", uuidv4());
  };

  const handleConstructionDeleteTrigger = (): void => {
    setIsDeleteDialogOpen(false);
  };

  const handleCalculationNavigation = (): void => {
    navigate(
      `${PathConstants.Constructions}/${id}/${PathConstants.Calculations}`,
    );
  };

  // TODO: Duplicate with the one from useConstructionsPageHook. Needs re-thinking
  const handleFreezeConstructionTrigger = async (): Promise<void> => {
    if (id !== undefined) {
      await withToast(
        async () => await freezeConstructionById(id),
        IsaacToastWrapperType.Full,
        undefined,
        `Triggering freezing construction...`,
        "Freezing process triggered successfully.",
        "Failed to trigger construction freezing!",
      );
    }
  };

  const items = useConstructionCommandBarItems(
    constructionStatus,
    isAllowToTakeAction,
    handleCalculationNavigation,
    handleConstructionDeleteTrigger,
    handleNewCalculationTrigger,
    handleFreezeConstructionTrigger,
  );

  return {items};
};

export default useConstructionCommandBar;
