import React, {useState} from "react";
import type {IEditableProps} from "./IEditableProps";
import {EditableType} from "../../../types/EditableItem";
import {Checkbox, Icon} from "@fluentui/react";
import styles from "./Editable.module.scss";

const Editable: React.FunctionComponent<IEditableProps> = ({
  value,
  children,
  type,
  onValidateInput,
  isValid,
  isDisabled,
}) => {
  const [isEditing, setEditing] = useState(false);

  const handleClick = (): void => {
    setEditing(true);
  };

  const handleBlur = (): void => {
    setEditing(false);
    onValidateInput();
  };
  if (!isEditing) {
    return (
      <div
        style={{display: "flex", alignItems: "center"}}
        onClick={handleClick}
        className={isValid ? "" : styles.error}>
        {type === EditableType.CheckBox ? (
          <Checkbox checked={Boolean(value)} disabled={isDisabled}></Checkbox>
        ) : (
          <p className={isDisabled ? styles.disabled : styles.normal}>
            {value}
          </p>
        )}
        {!isDisabled ? (
          <Icon
            style={{alignItems: "center", marginLeft: "5px"}}
            iconName="Edit"
          />
        ) : (
          <p style={{width: "19px"}}></p>
        )}
      </div>
    );
  }
  return <div onBlur={handleBlur}>{children}</div>;
};

export default Editable;
