import {createSlice} from "@reduxjs/toolkit";
import {type PayloadAction} from "@reduxjs/toolkit";

import type ICalculation from "../../types/ICalculation";
import type CalculationStatus from "../../types/CalculationStatus";
import type ICalculationConstructionDetails from "../../types/ICalculationConstructionDetails";

export interface ConstructionCalculationsState {
  constructionDetails: ICalculationConstructionDetails | undefined;
  calculations: ICalculation[];
  selectedCalculationId: string | undefined;
  selectedMastNo: string | undefined;
  selectedCalculationResults: any;
}

const initialState: ConstructionCalculationsState = {
  constructionDetails: undefined,
  selectedCalculationId: undefined,
  selectedMastNo: undefined,
  selectedCalculationResults: undefined,
  calculations: [],
};

export const constructionCalculationsSlice = createSlice({
  name: "constructionCalculations",
  initialState,
  reducers: {
    load: (
      state,
      {
        payload,
      }: PayloadAction<{
        currentCalculationId: string | undefined;
        selectedProfileCalculationId: string | undefined;
        calculations: ICalculation[];
        constructionDetails: any | undefined;
        currentProfileCalculationResults: any | undefined;
        currentMastNo: string | undefined;
      }>,
    ) => {
      state.calculations = payload.calculations;
      state.constructionDetails = payload.constructionDetails;
      state.selectedCalculationId = payload.currentCalculationId;
      state.selectedMastNo = payload.currentMastNo;
      state.selectedCalculationResults =
        payload.currentProfileCalculationResults;
    },
    switchMast: (state, {payload}: PayloadAction<string>) => {
      state.selectedMastNo = payload;
    },
    updateStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        calculationId: string;
        status: CalculationStatus;
      }>,
    ) => {
      const index = state.calculations.findIndex(
        c => c.calculationId === payload.calculationId,
      );

      if (index !== -1) {
        state.calculations[index].status = payload.status;
      }
    },
    updateData: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        results: any;
      }>,
    ) => {
      state.selectedCalculationResults = payload.results;

      const calculationIndex = state.calculations.findIndex(
        c => c.calculationId === payload.id,
      );
      const prevSelectedIndex = state.calculations.findIndex(c => c.selected);

      state.calculations[prevSelectedIndex].selected = false;
      state.selectedCalculationId =
        state.calculations[calculationIndex]?.calculationId;

      if (
        calculationIndex !== undefined &&
        state.calculations[calculationIndex] !== undefined
      ) {
        state.calculations[calculationIndex].selected = true;
      }
    },
    removeById: (state, {payload}: PayloadAction<string>) => {
      state.calculations = state.calculations.filter(
        c => c.calculationId !== payload,
      );
    },
    clear: state => {
      state.calculations = [];
      state.selectedCalculationId = undefined;
      state.selectedMastNo = undefined;
      state.selectedCalculationResults = undefined;
      state.constructionDetails = undefined;
    },
  },
});

export const {load, updateStatus, switchMast, updateData, removeById, clear} =
  constructionCalculationsSlice.actions;

export default constructionCalculationsSlice.reducer;
