import React from "react";

import type ICalculationsPanelListProps from "./CalculationsPanelListProps";
import CalculationsPanelListItem from "../sidePaneListItem/CalculationsPanelListItem";
import IsaacEmpty from "../../../../components/isaacEmpty/IsaacEmpty";

const CalculationsPanelList: React.FunctionComponent<
  ICalculationsPanelListProps
> = ({calculations, isAllowToTakeAction}) => {
  if (calculations.length > 0) {
    return (
      <>
        {calculations.map(calculation => (
          <CalculationsPanelListItem
            key={calculation.calculationId}
            calculation={calculation}
            isAllowToTakeAction={isAllowToTakeAction}
          />
        ))}
      </>
    );
  }
  return <IsaacEmpty />;
};

CalculationsPanelList.displayName = "CalculationsPanelList";

export default CalculationsPanelList;
