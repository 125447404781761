import {v4 as uuidv4} from "uuid";
import {useDispatch, useSelector} from "react-redux";
import {type Dispatch, type SetStateAction, useEffect, useState} from "react";
import {type ICommandBarItemProps} from "@fluentui/react";
import {type RootState} from "../../../store";
import type ApiResponse from "../../../services/api/types/ApiResponse";
import {
  type ConstructionStatusChangeMessage,
  MessageType,
} from "../../../services/signalR/models/Messages";
import useConstructionHook from "../../../hooks/useConstructionHook";
import useConstructionCommandBarItems from "./useConstructionCommandBarItems";
import ConstructionStatus from "../../../types/ConstructionStatus";
import {useAuth} from "../../../services/auth/hooks/useAuth";
import {deleteConstructions, update} from "../constructionsSlice";
import useCalculationHook from "../../../hooks/useCalculationHook";
import useIssacToastHook from "../../../hooks/useIssacToastHook";
import IsaacToastWrapperType from "../../../types/IsaacToastWrapperType";

const useConstructionsPageHook = (): {
  tagModalType: string | undefined;
  isTagDialogHidden: boolean;
  commandBarItems: ICommandBarItemProps[];
  setTagDialogHidden: Dispatch<SetStateAction<boolean>>;
  getAllConstructions: () => Promise<ApiResponse<any[]>>;
} => {
  const [tagModalType, setTagModalType] = useState<string | undefined>();
  const [isTagDialogHidden, setTagDialogHidden] = useState<boolean>(true);
  const [commandBarItems, setCommandBarItems] = useState<
    ICommandBarItemProps[]
  >([]);

  const {page} = useSelector((state: RootState) => state.constructions);
  const {messages} = useSelector((state: RootState) => state.signalRMessages);
  const {user} = useAuth();

  const dispatch = useDispatch();
  const {getAllConstructions} = useConstructionHook();
  const {deleteCalculationsByConstructionId} = useCalculationHook();
  const {
    downloadExcelInputMatrixById,
    downloadJsonInputMatrixById,
    freezeConstructionById,
    deleteConstructionById,
  } = useConstructionHook();
  const {withToast} = useIssacToastHook();

  const handleTagDialogDismiss = (type: string): void => {
    setTagModalType(type);
    setTagDialogHidden(!isTagDialogHidden);
  };

  useEffect(() => {
    setCommandBarItems(getCommandBarItems(page.filter(c => c.isSelected)));
  }, [page]);

  useEffect(() => {
    // Todo: Think about re-using this logic
    const relatedMessages = messages.filter(
      m => !m.isRead && m.user === user?.userName,
    );
    dispatch(
      update(
        relatedMessages
          .filter(
            message =>
              message.type === MessageType.ConstructionFrozen ||
              message.type === MessageType.ConstructionUnFrozen,
          )
          .map(message => {
            const statusChangeMessage =
              message as ConstructionStatusChangeMessage;
            return {
              id: statusChangeMessage.data.id,
              status:
                message.type === MessageType.ConstructionFrozen
                  ? ConstructionStatus.Frozen
                  : ConstructionStatus.Registered,
            };
          }),
      ),
    );
  }, [messages]);

  const handleDownloadExcelInputMatrixTrigger = async (
    items: any[],
  ): Promise<void> => {
    await Promise.all(
      items.map(async (item: any) => {
        await withToast(
          async () => await downloadExcelInputMatrixById(item.id as string),
          IsaacToastWrapperType.Full,
          new Map<string, string>([["Construction ID", item.id]]),
          `Preparing input matrix data for: ${item.name}...`,
          "Input matrix downloaded successfully.",
          "Failed to download input matrix!",
        );
      }),
    );
  };

  const handleDeleteConstructionTrigger = async (
    items: any[],
  ): Promise<void> => {
    await Promise.all(
      items.map(async (item: any) => {
        const correlationId = uuidv4();
        await withToast(
          async () => {
            let result = await deleteCalculationsByConstructionId(
              item.id as string,
              correlationId,
            );
            if (!result.success) {
              return result;
            }
            result = await deleteConstructionById(
              item.id as string,
              correlationId,
            );

            if (!result.success) {
              return result;
            } else {
              dispatch(deleteConstructions([item.id as string]));
              return result;
            }
          },
          IsaacToastWrapperType.Full,
          new Map<string, string>([["Construction ID", item.id]]),
          `Deleting construction: ${item.name}...`,
          `Deleted construction: ${item.name} successfully.`,
          `Failed to deleted construction: ${item.name}!`,
        );
      }),
    );
  };

  const handleFreezeUnfreezeConstructionTrigger = async (
    items: any[],
  ): Promise<void> => {
    await Promise.all(
      items.map(async (item: any) => {
        await withToast(
          async () => await freezeConstructionById(item.id as string),
          IsaacToastWrapperType.Full,
          new Map<string, string>([["Construction ID", item.id]]),
          `Triggering freezing construction: ${item.name}...`,
          "Freezing process triggered successfully.",
          "Failed to trigger construction freezing!",
        );
      }),
    );
  };

  const handleDownloadJsonInputMatrixTrigger = async (
    items: any[],
  ): Promise<void> => {
    await Promise.all(
      items.map(async (item: any) => {
        await withToast(
          async () => await downloadJsonInputMatrixById(item.id as string),
          IsaacToastWrapperType.Full,
          new Map<string, string>([["Construction ID", item.id]]),
          `Preparing input matrix data for: ${item.name}...`,
          "Input matrix downloaded successfully.",
          "Failed to download input matrix!",
        );
      }),
    );
  };

  const getCommandBarItems = useConstructionCommandBarItems(
    handleTagDialogDismiss,
    handleDownloadExcelInputMatrixTrigger,
    handleDeleteConstructionTrigger,
    handleFreezeUnfreezeConstructionTrigger,
    handleDownloadJsonInputMatrixTrigger,
  );

  return {
    tagModalType,
    isTagDialogHidden,
    commandBarItems,
    setTagDialogHidden,
    getAllConstructions,
  };
};

export default useConstructionsPageHook;
