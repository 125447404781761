import React from "react";
import styles from "./IsaacList.module.scss";
import {List} from "@fluentui/react";

import type IsaacListProps from "./IsaacListProps";

const IsaacList = <T,>({
  items,
  onRenderCell,
  className,
}: IsaacListProps<T>): JSX.Element => {
  return (
    <>
      {items !== undefined && (
        <List
          className={className ?? styles.default}
          items={items}
          onRenderCell={onRenderCell}
        />
      )}
    </>
  );
};

export default IsaacList;
