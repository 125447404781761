import type {IComboBoxOption} from "@fluentui/react";

export const setToComboBoxOption = <T>(arr: Set<T>): IComboBoxOption[] =>
  Array.from(arr).map((item: T) => {
    const combo: IComboBoxOption = {
      key: item as string,
      text: item as string,
    };

    return combo;
  });
