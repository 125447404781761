enum CalculationStatus {
  Created = "Created",
  Pending = "Pending",
  ValidProfilesFound = "ValidProfilesFound",
  Failed = "Failed",
  NoValidProfilesFound = "NoValidProfilesFound",
  Interrupted = "Interrupted",
  Completed = "Completed",
  Ongoing = "Ongoing",
  PartiallyFailed = "PartiallyFailed",
  Deleted = "Deleted",
  PartiallyCompleted = "PartiallyCompleted",
}

export default CalculationStatus;
