export const HeadersTooltips = {
  DBL: {
    image: `${process.env.PUBLIC_URL}/images/res1.jpg`,
    title: "Deformation at beam level",
    des: "This is the horizontal deformation perpendicular to the track at beam level",
  },
  zt: {
    image: `${process.env.PUBLIC_URL}/images/res2.png`,
    title: "Deflection at mast top",
    des: "This is the horizontal deflection perpendicular to the track at mast top",
  },
  DML: {
    image: `${process.env.PUBLIC_URL}/images/res3.png`,
    title: "Deflection over mast length",
    des: "i.e. the deflected mast must lie between 2 parabolic curves and may not extend beyond them",
  },
  ft: {
    image: `${process.env.PUBLIC_URL}/images/res4.png`,
    title: "Tensions",
    des: "Tensions in the mast steel profile",
  },
  BT: {
    image: `${process.env.PUBLIC_URL}/images/res5.png`,
    title: "Buckling and Tilt",
    des: "Buckling and Tilt of the mast (not necessarily in the middle)",
  },
};
