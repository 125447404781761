import React from "react";
import styles from "../../../constructions/Constructions.module.scss";
import {useSelector} from "react-redux";

import {type RootState} from "../../../../store";
import IsaacEntityNavigation from "../../../../components/isaacEntityNavigation/IsaacEntityNavigation";
import PathConstants from "../../../../routes/pathConstants";

const CalculationNavigation: React.FunctionComponent = () => {
  const {nextId, prevId} = useSelector(
    (state: RootState) => state.construction,
  );
  return (
    <div className={styles.navigation}>
      <IsaacEntityNavigation
        nextPath={
          nextId !== null
            ? `${PathConstants.Constructions}/${nextId}/${PathConstants.Calculations}`
            : null
        }
        prevPath={
          prevId !== null
            ? `${PathConstants.Constructions}/${prevId}/${PathConstants.Calculations}`
            : null
        }
        nextTooTip="Got to next construction calculation."
        prevTooTip="Got to previous construction calculation."
      />
    </div>
  );
};

export default CalculationNavigation;
