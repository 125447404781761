import React, {useState} from "react";
import styles from "../../../construction/Construction.module.scss";

import type CalculationTagsProps from "./CalculationTagsProps";
import {IconButton} from "@fluentui/react";

import ConfirmTagDeletionDialog from "../../../../components/isaacTagsDialogs/deletion/ConfirmTagDeletionDialog";
import AddTagsDialog from "../../../../components/isaacTagsDialogs/addition/AddTagsDialog";
import {useAuth} from "../../../../services/auth/hooks/useAuth";

const CalculationTags: React.FunctionComponent<CalculationTagsProps> = ({
  construction,
}) => {
  const {tags}: {tags: string[]} = construction;
  const [isAddDialogHidden, setIsAddDialogHidden] = useState<boolean>(true);
  const [isDeleteDialogHidden, setIsDeleteDialogHidden] =
    useState<boolean>(true);
  const [tagToDelete, setTagToDelete] = useState<string>();
  const {user} = useAuth();
  const isAllowedToTakeAction = user?.isAllowedToTakeAction();

  const handleAddDialogDismiss = (): void => {
    setIsAddDialogHidden(true);
  };

  const handleDeleteDialogDismiss = (): void => {
    setIsDeleteDialogHidden(true);
  };

  return (
    <>
      <ul className={styles.tagsContainer}>
        {tags?.map((tag, index) => {
          return (
            isAllowedToTakeAction && (
              <li className={styles.tag} key={index}>
                <span className={styles.name}>{tag}</span>
                <span className={styles.removeBtn}>
                  <IconButton
                    iconProps={{iconName: "Clear"}}
                    title="Remove"
                    ariaLabel="Remove"
                    id="tag"
                    onClick={() => {
                      setTagToDelete(tag);
                      setIsDeleteDialogHidden(false);
                    }}
                  />
                </span>
              </li>
            )
          );
        })}
        {isAllowedToTakeAction && (
          <li key="add" className={styles.tag} style={{borderRadius: "2px"}}>
            <span className={styles.addBtn}>
              <IconButton
                iconProps={{iconName: "Add"}}
                title="Add new tag"
                ariaLabel="Add new tag"
                onClick={() => {
                  setIsAddDialogHidden(false);
                }}
              />
            </span>
          </li>
        )}
      </ul>

      <ConfirmTagDeletionDialog
        isHidden={isDeleteDialogHidden}
        handleDismiss={handleDeleteDialogDismiss}
        tag={tagToDelete}
        constructionId={construction.id}
      />

      <AddTagsDialog
        isHidden={isAddDialogHidden}
        handleDismiss={handleAddDialogDismiss}
      />
    </>
  );
};

export default CalculationTags;
