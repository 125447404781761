import React from "react";
import styles from "../../Construction.module.scss";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {TooltipHost, IconButton} from "@fluentui/react";

import IsaacLanguageSwitcher from "../../../../components/isaacLanguageSwitcher/IsaacLanguageSwitcher";
import PathConstants from "../../../../routes/pathConstants";
import type {RootState} from "../../../../store";

const ConstructionNavigation: React.FunctionComponent = () => {
  const {nextId, prevId} = useSelector(
    (state: RootState) => state.construction,
  );
  const navigate = useNavigate();
  return (
    <div className={styles.navigation}>
      <IsaacLanguageSwitcher />

      {prevId !== null && (
        <TooltipHost content="Go to previous construction.">
          <IconButton
            iconProps={{iconName: "ChevronLeftMed"}}
            onClick={() => {
              navigate(`${PathConstants.Constructions}/${prevId}`);
            }}
          />
        </TooltipHost>
      )}
      {nextId !== null && (
        <TooltipHost content="Go to next construction.">
          <IconButton
            iconProps={{iconName: "ChevronRightMed"}}
            onClick={() => {
              navigate(`${PathConstants.Constructions}/${nextId}`);
            }}
          />
        </TooltipHost>
      )}
    </div>
  );
};

export default ConstructionNavigation;
