import React from "react";
import {Stack} from "@fluentui/react";
import styles from "./MastProfileAndFoundationPanel.module.scss";
import type MastProfileAndFoundationPanelProps from "./MastProfileAndFoundationPanelProps";
import CalculationConstructionDetails from "../constructionDetails/CalculationConstructionDetails";

const MastProfileAndFoundationPanel: React.FunctionComponent<
  MastProfileAndFoundationPanelProps
> = ({selectedMastNo, constructionDetails, profileCalculationResults}) => {
  return (
    <Stack grow={1} className={styles.container}>
      {selectedMastNo != null && constructionDetails !== undefined && (
        <>
          <h3 className={styles.header}>Mast profile & foundation</h3>
          <CalculationConstructionDetails
            input={constructionDetails}
            profileCalculationResults={profileCalculationResults}
            selectedMastNumber={selectedMastNo}
          />
        </>
      )}
    </Stack>
  );
};

export default MastProfileAndFoundationPanel;
