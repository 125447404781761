/* eslint-disable @typescript-eslint/no-unsafe-argument */
export const formatDateToString = (date?: Date): string =>
  date === undefined
    ? ""
    : date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

export const formatDateStringToString = (date?: string): string | undefined => {
  if (date !== undefined) {
    return formatDateToString(new Date(date));
  }
  return undefined;
};

export const formatDateStringToDate = (dateString?: string): Date => {
  if (dateString !== undefined) {
    if (dateString.includes("/")) {
      const dateParts = dateString.slice(0, dateString.indexOf(" ")).split("/");

      if (dateParts.length === 3) {
        const [day, month, year] = dateParts.map(Number);

        if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
          return new Date(year, month - 1, day);
        }
      }
    }
    return new Date(dateString);
  }
  throw new Error("Invalid date!");
};

export const getOrderByCreatedDate = (constructions: any[]): any[] => {
  return [
    ...constructions?.map(c => ({
      ...c,
      sortCreatedOn: c.sortCreatedOn.toJSON(),
    })),
  ]?.sort((constructionA: any, constructionB: any) => {
    return (
      new Date(constructionB.sortCreatedOn).getTime() -
      new Date(constructionA.sortCreatedOn).getTime()
    );
  });
};
