// Note: This file should not be here. Move it closer to it's logical location or refactor it.
import type {
  EditableConstruction,
  EditableProperty,
} from "../../types/EditableConstruction";
import {EDITABLE} from "../../types/EditableItem";
import core from "../../utils/_legacy/SrvCore";

/** @deprecated Tagged for removal. Editable props are now part of redux state. */
const removeMirrorFromValue = (value: string | undefined): string => {
  if (value !== undefined) {
    return isValueMirrored(value)
      ? value?.substring(value.indexOf("-") + 1, value.length)
      : checkIfBoolean(value);
  }
  return "";
};

const checkIfBoolean = (value: string | boolean): string => {
  if (typeof value === "boolean") {
    return String(value);
  }
  return value;
};

/** @deprecated Tagged for removal. Editable props are now part of redux state. */
const isValueMirrored = (value: string | undefined): boolean => {
  if (value !== undefined && typeof value === "string") {
    return value.includes("-");
  }
  return false;
};

/** @deprecated Tagged for removal. Editable props are now part of redux state. */
function saveEditablePropertyToLocalStorage(
  property: string,
  entity: string,
  constructionId?: string,
  entityId?: string,
  value?: string,
): EditableProperty[] | undefined {
  const editConstructionJson = localStorage.getItem(
    EDITABLE + "_" + constructionId,
  );

  if (editConstructionJson !== undefined && editConstructionJson !== null) {
    const editConstruction = JSON.parse(editConstructionJson);

    const editableProperty: EditableProperty = {
      entityName: entity,
      propertyName: core.convertToFirstLetter(property),
      entityId: entityId ?? "",
      propertyValue: removeMirrorFromValue(value),
    };

    const construction = removeExistingProperty(
      editConstruction as EditableConstruction,
      editableProperty,
    );

    construction.editableProperties.push(editableProperty);

    if (isValueMirrored(value)) {
      const isMirroredProp: EditableProperty = {
        entityName: entity,
        propertyName: "IsMirrored",
        entityId: entityId ?? "",
        propertyValue: "true",
      };
      construction.editableProperties.push(isMirroredProp);
    }

    localStorage.setItem(
      "editable_" + constructionId,
      JSON.stringify(construction),
    );

    return construction.editableProperties;
  }
}

/** @deprecated Tagged for removal. */
function initLocalStorageForEditableConstruction(constructionId: string): void {
  const editable: EditableConstruction = {
    constructionId,
    editableProperties: [],
  };

  localStorage.setItem(
    EDITABLE + "_" + constructionId,
    JSON.stringify(editable),
  );
}

/** @deprecated Tagged for removal. Editable props are now part of redux state. */
function removeExistingProperty(
  editableConstruction: EditableConstruction,
  editableProperty: EditableProperty,
): EditableConstruction {
  let properties: any[] = [];
  if (editableConstruction.editableProperties.length > 0) {
    properties = editableConstruction.editableProperties.filter(
      currentProperty => {
        if (
          currentProperty.entityName === editableProperty.entityName &&
          currentProperty.entityId === editableProperty.entityId &&
          currentProperty.propertyName === editableProperty.propertyName
        ) {
          return false;
        }
        return true;
      },
    );
  }
  return {
    constructionId: editableConstruction.constructionId,
    editableProperties: properties,
  };
}

/** @deprecated Tagged for removal. Editable props are now part of redux state. */
function getEditConstructionFromLocalStorage(
  constructionId: string,
): EditableConstruction | null {
  const editConstructionJson = localStorage.getItem(
    EDITABLE + "_" + constructionId,
  );
  if (editConstructionJson !== null) {
    return JSON.parse(editConstructionJson);
  }
  return null;
}

export {
  saveEditablePropertyToLocalStorage,
  initLocalStorageForEditableConstruction,
  getEditConstructionFromLocalStorage,
  isValueMirrored,
  removeMirrorFromValue,
};
