import React from "react";
import {
  DefaultButton,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
  PrimaryButton,
} from "@fluentui/react";

import styles from "./IsaacDeleteConstructionDialog.module.scss";
import IsaacDialog from "../isaacDialog/IsaacDialog";
import type IsaacDeleteConstructionDialogProps from "./IsaacDeleteConstructionDialogProps";
import useIsaacDeleteConstructionHook from "./useIsaacDeleteConstructionHook";

const IsaacDeleteConstructionDialog: React.FunctionComponent<
  IsaacDeleteConstructionDialogProps
> = ({isHidden, handleDialogDismiss}) => {
  const {handleDelete} = useIsaacDeleteConstructionHook(handleDialogDismiss);

  return (
    <IsaacDialog
      handleDialogDismiss={handleDialogDismiss}
      modalProps={{
        titleAriaId: "NewCalculation",
        subtitleAriaId: "mySubTextId",
        isBlocking: true,
        containerClassName: `ms-dialogMainOverride ${styles.deleteDialog}`,
      }}
      dialogContentProps={{
        type: DialogType.normal,
        title: "Action confirmation required!",
        subText: "",
      }}
      isHidden={isHidden}>
      <div className={styles.defaultContainer}>
        <div>Are you sure you want to delete the construction(s) ?</div>
        <div className={styles.warningMsg}>
          <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={true}
            dismissButtonAriaLabel="Close">
            This action will also delete all calculations related to a
            construction
          </MessageBar>
        </div>
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              handleDelete();
            }}
            text="Delete"
          />
          <DefaultButton
            onClick={() => {
              handleDialogDismiss(prevState => !prevState);
            }}
            text="Cancel"
          />
        </DialogFooter>
      </div>
    </IsaacDialog>
  );
};

export default IsaacDeleteConstructionDialog;
