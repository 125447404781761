import {createSlice} from "@reduxjs/toolkit";
import {type PayloadAction} from "@reduxjs/toolkit";

interface IAppError {
  exception: string | undefined;
  message: string | undefined;
}
export interface AppErrorState {
  errors: IAppError[] | undefined;
}

const initialState: AppErrorState = {
  errors: undefined,
};

export const appErrorSlice = createSlice({
  name: "appErrors",
  initialState,
  reducers: {
    add: (state, {payload}: PayloadAction<IAppError>) => {
      state.errors = [payload];
    },
    flush: state => {
      state.errors = undefined;
    },
  },
});

export const {add, flush} = appErrorSlice.actions;

export default appErrorSlice.reducer;
